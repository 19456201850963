import { useState } from "react";

const useSession = () => {
  const [session, setSession] = useState(() => {
    try {
      return JSON.parse(sessionStorage.getItem("session") || "null");
    } catch (error) {
      console.error("Error while parsing session from sessionStorage", error);
      return null;
    }
  });

  return { session, setSession };
};

export default useSession;
