import { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const NavigationHistoryContext = createContext<any>(null);

export const NavigationHistoryProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [history, setHistory] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const customNavigate = (path: string) => {
    setHistory((prev) =>
      [...prev, `${location.pathname}${location?.search}`].slice(-50)
    ); // Store current path before navigating
    navigate(path);
  };

  return (
    <NavigationHistoryContext.Provider
      value={{ history, navigate: customNavigate }}
    >
      {children}
    </NavigationHistoryContext.Provider>
  );
};

export const useNavigationHistory = () => useContext(NavigationHistoryContext);
