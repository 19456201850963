import { SolButton, SolIcon } from "@solstice/sol-react";
import { Modal } from "ui-molecules";
import SharePointIcon from "assets/images/ms-sharepoint-svgrepo.svg";

interface ContactModalProps {
  isOpen: boolean;
  setIsOpen: any;
}

const ContactModal: React.FC<ContactModalProps> = ({ isOpen, setIsOpen }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="medium">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between relative">
          <h2 className="text-xl text-center w-full font-semibold mt-1">
            Contact Support
          </h2>
          <div className="absolute right-0 top-0">
            <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
              <SolIcon icon="close" />
            </SolButton>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="py-3">
        <p className="text-center">
          We’re here to help! Please include the profile you need assistance
          with and any related information.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex items-center justify-center space-x-8 ml-20">
          <SolButton
            variant="secondary"
            onSol-click={() => {
              window.open("mailto:profile.management@jll.com", "_blank");
            }}
          >
            <SolIcon icon="mail" />
            Contact Support
          </SolButton>
          <SolButton
            variant="secondary"
            onSol-click={() => {
              window.open(
                "https://jll2.sharepoint.com/sites/ProfileManagement",
                "_blank"
              );
            }}
          >
            <img src={SharePointIcon} className="w-5 h-5" />
            Profile Management SharePoint Site
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ContactModal;
