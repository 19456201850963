import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { GlobalContextProvider, NavigationHistoryProvider } from "context";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { HelmetProvider } from "react-helmet-async";
import "@solstice/sol-stencil/dist/sol-stencil/sol-stencil.css";
import "./index.css";
import { defineCustomElements } from "@solstice/sol-stencil/loader/index";
import { initializeDatadog } from "./utils/datadog"; // Import Datadog setup

// Initialize Datadog before the app starts
initializeDatadog();

defineCustomElements(window);

const rootElement = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

rootElement.render(
  <HelmetProvider>
    <BrowserRouter>
      <GlobalContextProvider>
        <NavigationHistoryProvider>
          <App />
        </NavigationHistoryProvider>
      </GlobalContextProvider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
