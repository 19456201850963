import {
  SolButton,
  SolIcon,
  SolIconBubble,
  SolTextField,
} from "@solstice/sol-react";
import {
  COUNTRY_ID,
  SEARCH_RESULT_LIMIT,
  SET_ADD_TAG_MODAL,
  STATUS_ACTIVE,
} from "constant";
import { GlobalContext } from "context";
import { useApiCall, useSession } from "hooks";
import { useContext, useEffect, useState } from "react";
import {
  getCountryAPI,
  getStateAPI,
  postCityAPI,
  postCountryAPI,
  postIndustryAPI,
  postMarketAPI,
  postServiceAPI,
  postStateAPI,
} from "services";
import { SelectAutoComplete, Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import { capitalize } from "utils";

interface AddTagModalProps {
  kind: "industry" | "service" | "country" | "state" | "city" | "market";
  loadData?: any;
}

const AddTagModal: React.FC<AddTagModalProps> = ({ kind, loadData }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { session } = useSession();
  const { isAddTagModalOpen, meta } = state;
  const [postIndustry] = useApiCall(postIndustryAPI);
  const [postService] = useApiCall(postServiceAPI);
  const [postCountry] = useApiCall(postCountryAPI);
  const [postState] = useApiCall(postStateAPI);
  const [postCity] = useApiCall(postCityAPI);
  const [postMarket] = useApiCall(postMarketAPI);
  const [getCountry] = useApiCall(getCountryAPI);
  const [getState] = useApiCall(getStateAPI);
  const [tagName, setTagName] = useState("");
  const [isBlur, setIsBlur] = useState(false);

  const country_id_usa =
    meta?.country?.find(
      (item: any) => item?.name?.toLowerCase() === "united states"
    )?.id || COUNTRY_ID?.usa;
  const country_id_canada =
    meta?.country?.find((item: any) => item?.name?.toLowerCase() === "canada")
      ?.id || COUNTRY_ID?.canada;

  const [countryValue, setCountryValue] = useState({
    value: undefined,
    touched: false,
  });
  const [stateValue, setStateValue] = useState({
    value: undefined,
    touched: false,
  });
  const [regionValue, setRegionValue] = useState<any>({
    value: undefined,
    touched: false,
  });

  const isState =
    ((kind === "city" || kind === "market") &&
      (countryValue?.value as any)?.value === country_id_usa) ||
    (countryValue?.value as any)?.value === country_id_canada;

  const regionOptions = Object.keys(meta?.["region"] || [])?.map(
    (key: number | string) => ({
      value: Number(key),
      label: meta?.["region"]?.[key],
    })
  );

  useEffect(() => {
    if (!isAddTagModalOpen) return;
    setTagName("");
    setIsBlur(false);
    return () => {
      setCountryValue({
        value: undefined,
        touched: false,
      });
      setStateValue({
        value: undefined,
        touched: false,
      });
    };
  }, [isAddTagModalOpen]);

  const handleClose = () => {
    dispatch({
      type: SET_ADD_TAG_MODAL,
      payload: false,
    });
  };

  const handleSubmit = () => {
    try {
      let API;
      let payload: any = { name: tagName, updated_user: session?.id };
      switch (kind) {
        case "service":
          API = postService;
          break;
        case "country":
          payload = {
            ...payload,
            region: (regionValue?.value as any)?.value,
          };
          API = postCountry;
          break;
        case "state":
          API = postState;
          break;
        case "city":
          API = postCity;
          if (isState) {
            payload = {
              ...payload,
              country: (countryValue?.value as any)?.value,
              state: (stateValue?.value as any)?.value,
            };
          } else {
            payload = {
              ...payload,
              country: (countryValue?.value as any)?.value,
            };
          }
          break;
        case "market":
          API = postMarket;
          payload = {
            ...payload,
            regionValue: (regionValue?.value as any)?.value,
            country: (countryValue?.value as any)?.value,
            state: (stateValue?.value as any)?.value || null,
          };
          break;
        default:
          API = postIndustry;
      }
      API(payload).then((res: any) => {
        handleClose();
        loadData();
        if (!!res) Toast.success(`Successfully created tag "${tagName}"`);
      });
    } catch (err) {}
  };

  const loadCountryOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return getCountry({
      keyword,
      page,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      region:
        // @ts-ignore
        kind === "market" ? [(regionValue?.value as any)?.value] || [] : [],
      sort: "name",
      direction: "asc",
    }).then((res: any) => {
      return {
        options: res?.docs?.map((country: any) => {
          return {
            value: country.pk,
            label: country?.name,
          };
        }),
        hasMore: page < res?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const loadStateOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return getState({
      keyword,
      page,
      limit: SEARCH_RESULT_LIMIT,
      country: [(countryValue?.value as any)?.value],
      active_status: [STATUS_ACTIVE],
      sort: "name",
      direction: "asc",
    }).then((res: any) => {
      return {
        options: res?.docs?.map((state: any) => {
          return {
            value: state.pk,
            label: state?.name,
          };
        }),
        hasMore: page < res?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  return (
    <Modal isOpen={isAddTagModalOpen} setIsOpen={handleClose} size="default">
      <Modal.Header>
        <div className="flex flex-row justify-between items-start">
          <SolIconBubble icon="note_add" size="56" />
          <SolButton variant="tertiary" onClick={handleClose}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-4">
          <h3 className="text-xl mb-6">Add {capitalize(kind)}</h3>

          {(kind === "market" || kind === "country") && (
            <SelectAutoComplete
              placeholder="Region"
              label="Region"
              size="sm"
              options={regionOptions}
              required
              name="region"
              value={regionValue?.value}
              onChange={(e) => {
                setRegionValue((prev: any) => ({ ...prev, value: e }));
              }}
              onBlur={() =>
                setRegionValue((prev: any) => ({ ...prev, touched: true }))
              }
              error={
                regionValue?.touched
                  ? regionValue?.value
                    ? undefined
                    : "This field is required"
                  : undefined
              }
              key={`region_${JSON.stringify(regionValue?.value)}`}
            />
          )}

          {(kind === "city" || (kind === "market" && regionValue?.value)) && (
            <SelectAutoComplete
              label="Country"
              required
              size="sm"
              loadOptions={loadCountryOptions}
              value={countryValue?.value}
              onChange={(e) => {
                setCountryValue((prev) => ({ ...prev, value: e }));
              }}
              onBlur={() =>
                setCountryValue((prev) => ({ ...prev, touched: true }))
              }
              error={
                countryValue?.touched
                  ? countryValue?.value
                    ? undefined
                    : "This field is required"
                  : undefined
              }
              name="country"
              key={`country_${JSON.stringify(countryValue?.value)}`}
            />
          )}

          {isState && (
            <SelectAutoComplete
              label="State"
              required
              size="sm"
              loadOptions={loadStateOptions}
              value={stateValue?.value}
              onChange={(e) => {
                setStateValue((prev) => ({ ...prev, value: e }));
              }}
              onBlur={() =>
                setStateValue((prev) => ({ ...prev, touched: true }))
              }
              error={
                stateValue?.touched
                  ? stateValue?.value
                    ? undefined
                    : "This field is required"
                  : undefined
              }
              name="state"
              key={`state_${JSON.stringify(stateValue?.value)}`}
            />
          )}

          <SolTextField
            label={`${capitalize(kind)} name`}
            required
            placeholder={`Enter ${capitalize(kind)} name`}
            size="small"
            value={tagName}
            onSol-input={(e) => {
              setTagName(e?.detail as string);
            }}
            onSol-blur={() => setIsBlur(true)}
            errorLabel={
              isBlur && !tagName?.length ? "This field is required" : undefined
            }
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 w-full justify-end mt-6">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={handleClose}
          >
            Cancel
          </SolButton>
          <SolButton
            className="!w-[96px] sol-w-full"
            disabled={
              !tagName?.length ||
              (kind === "city" && !countryValue?.value) ||
              (isState && !stateValue?.value)
            }
            onSol-click={() => handleSubmit()}
          >
            Save
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AddTagModal;
