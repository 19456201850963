import queryString from "query-string";
import axios from "../axiosConfig";
import { API_PREFIX_URL } from "constant";
import { APIProps } from "types";

export const getUserAPI = async (props: APIProps) => {
  const param_str = queryString.stringify(props, {
    arrayFormat: "bracket",
  });
  const res = await axios.get(`${API_PREFIX_URL}/user/?${param_str}`);
  return res;
};

export const postUserGroupAPI = async (payload: {
  user: number;
  group: number;
}) => {
  const res = await axios.post(`${API_PREFIX_URL}/user/group/`, payload);
  return res;
};

export const deleteUserGroupAPI = async (props: any) => {
  const res = await axios.delete(
    `${API_PREFIX_URL}/user/group/${props.user}/?group=${props.group}&user=${props.user}`
  );
  return res;
};

export const postUserAPI = async (data: any) => {
  const res = await axios.post(`${API_PREFIX_URL}/user/`, data);
  return res;
};

export const postFirstSessionAPI = async (payload: {
  markets?: number[];
  countries?: number[];
}) => {
  const res = await axios.post(
    `${API_PREFIX_URL}/user/first-session/`,
    payload
  );
  return res;
};

export const postFirstBrokerAPI = async () => {
  const res = await axios.post(`${API_PREFIX_URL}/brokers/create/`);
  return res;
};
