import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { useSearchParams } from "react-router-dom";
import { patchProfileAPI } from "services";
import { BrokerProps, ProfileProps } from "types";
import { convertFromHTML, convertToHTML } from "draft-convert";
import * as Yup from "yup";
import { SolButton } from "@solstice/sol-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import cn from "classnames";
import {
  bioValidation,
  getApiPayloadFromForm,
  parseOriginalJSON,
} from "utils";
import { GlobalContext } from "context";
import {
  EDITOR_TOOLBAR_OPTIONS,
  SET_ALERT_MODAL,
  SET_LOADING_MODAL,
} from "constant";

interface MainBioProps {
  profile: ProfileProps | null;
  setProfile?: any;
  handleGPT?: any;
  broker?: BrokerProps | null;
  reloadBroker: () => void;
}

const INITIAL_VALUES = {
  bio: EditorState.createEmpty(),
};

const validationSchema = Yup.object().shape({
  bio: Yup.string().required("This field is required"),
});

const MainBio = forwardRef(
  ({ profile, setProfile, broker, reloadBroker }: MainBioProps, ref) => {
    const { dispatch } = useContext(GlobalContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const [patchProfile] = useApiCall(patchProfileAPI);

    useEffect(() => {
      if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
        return;
      let formValues: any = {};
      Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
        if (key === "bio") {
          if (!profile?.bio) {
            formValues = {
              ...formValues,
              [key]: EditorState.createEmpty(),
            };
            return;
          }

          const rawContentState = parseOriginalJSON(profile?.bio);
          const contentState: any = convertFromHTML(rawContentState);
          formValues = {
            ...formValues,
            [key]: EditorState.createWithContent(contentState),
          };
          return;
        }
        formValues = {
          ...formValues,
          [key]: (profile as any)?.[key] || "",
        };
      });
      setValues({
        ...formValues,
      });
    }, [profile]);

    useImperativeHandle(ref, () => ({
      saveBioData: () => {
        handleUpdate();
      },
    }));

    const { handleBlur, setFieldValue, values, errors, touched, setValues } =
      useFormik({
        initialValues: INITIAL_VALUES,
        validationSchema: validationSchema,
        onSubmit: async () => {},
      });

    const handleUpdate = () => {
      try {
        const contentState = values?.bio?.getCurrentContent();
        const rawContentState = convertToHTML(contentState);
        const contentString = JSON.stringify(rawContentState);
        const value = contentString;

        let payload = getApiPayloadFromForm(profile, {
          bio: value,
        });
        if (!payload || !Object.keys(payload)?.length) {
          searchParams.set("mode", "general");
          searchParams.set("active", "tutorial");
          setSearchParams(searchParams);
          return;
        }

        const action = () => {
          try {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: true,
                title: "Saving bio content",
              },
            });
            patchProfile({
              pk: profile?.id,
              ...payload,
            })
              .then((res: any) => {
                if (!res) return;
                setProfile(res);
                reloadBroker();
              })
              .finally(() => {
                dispatch({
                  type: SET_LOADING_MODAL,
                  payload: {
                    open: false,
                    title: "",
                  },
                });
                searchParams.set("mode", "general");
                searchParams.set("active", "tutorial");
                setSearchParams(searchParams);
              });
          } catch (err) {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                title: "",
              },
            });
          }
        };
        const validate = bioValidation(
          JSON.parse(value),
          broker?.leadership !== 1
        );
        if (!!validate?.length) {
          dispatch({
            type: SET_ALERT_MODAL,
            payload: {
              open: true,
              kind: "error",
              title: `Bio format warning`,
              description: validate,
              icon: "warning",
              btn3: null,
              btn1: {
                label: "Cancel",
                onClick: () => {
                  dispatch({
                    type: SET_ALERT_MODAL,
                    payload: {
                      open: false,
                      kind: "",
                      title: "",
                      description: "",
                      icon: "",
                      btn1: null,
                      btn2: null,
                      btn3: null,
                    },
                  });
                },
              },
              btn2: {
                label: "Save Anyway",
                onClick: () => {
                  dispatch({
                    type: SET_ALERT_MODAL,
                    payload: {
                      open: false,
                      kind: "",
                      title: "",
                      description: "",
                      icon: "",
                      btn1: null,
                      btn2: null,
                      btn3: null,
                    },
                  });
                  action();
                },
              },
            },
          });
          return;
        }
        action();
      } catch (err) {}
    };

    return (
      <div className="mb-12">
        <div className="relative border border-jll-light-stroke-default rounded">
          <Editor
            editorState={values?.bio as any}
            toolbarClassName="hidden"
            wrapperClassName="border-none"
            editorClassName={cn("px-5", {})}
            onEditorStateChange={(e) => {
              setFieldValue("bio", e);
            }}
            onBlur={(e) => {
              handleBlur(e);
            }}
            toolbar={EDITOR_TOOLBAR_OPTIONS}
          />
        </div>

        <div className="flex flex-row items-center justify-between my-10">
          <SolButton
            variant="outlined"
            onSol-click={() => {
              searchParams.set("mode", "general");
              searchParams.set("active", "tutorial");
              setSearchParams(searchParams);
            }}
          >
            Cancel
          </SolButton>
        </div>
      </div>
    );
  }
);

export default MainBio;
