import axios from "axiosConfig";
import { HTTP_ERROR_UNAUTHORIZED } from "constant";
import Cookies from "js-cookie";
import { useState, useCallback } from "react";
import { logoutApi, refreshApi } from "services";
import { Toast } from "ui-atoms";
import { decodeJwt, parseJSON } from "utils";

const refreshToken = async () => {
  const okta_tokens =
    parseJSON(localStorage.getItem("okta_tokens") || "") ||
    parseJSON(Cookies.get("okta_tokens") || "");

  if (okta_tokens) {
    try {
      const refresh_token = okta_tokens?.["refresh_token"];
      const scope = okta_tokens?.["scope"];
      const result = await refreshApi({
        refresh_token,
        scope,
      });
      if (!result?.data) return;
      await localStorage.setItem("okta_tokens", JSON.stringify(result?.data));
      const access_token = result?.data?.["access_token"];
      if (access_token) {
        axios.defaults.headers.common["Authorization"] =
          decodeURIComponent(access_token);
      }
    } catch (err) {
      const res: any = await logoutApi();
      if (res?.data || res?.redirect) {
        window.location.href = res?.data?.redirect || res?.redirect;
        sessionStorage.clear();
        localStorage.clear();
        Cookies.remove("okta_tokens");
      }
    }
  }
};

let updateRefreshTokenInterval: any;

const startRefreshTokenInterval = () => {
  updateRefreshTokenInterval = setInterval(() => {
    refreshToken();
  }, 3509000);
};

const resetRefreshTokenInterval = () => {
  if (updateRefreshTokenInterval) clearInterval(updateRefreshTokenInterval);
  startRefreshTokenInterval();
};

const getAccessToken = async () => {
  try {
    const okta_tokens =
      parseJSON(localStorage.getItem("okta_tokens") || "") ||
      parseJSON(Cookies.get("okta_tokens") || "");

    let access_token = okta_tokens?.["access_token"];
    if (!access_token?.length) return;

    const decodedToken = decodeJwt(access_token);
    if (decodedToken?.exp * 1000 <= Date.now() && okta_tokens) {
      try {
        const refresh_token = okta_tokens?.["refresh_token"];
        const scope = okta_tokens?.["scope"];
        const result = await refreshApi({
          refresh_token,
          scope,
        });
        if (!result?.data) return;
        await localStorage.setItem("okta_tokens", JSON.stringify(result?.data));
        access_token = result?.data?.["access_token"];
      } catch (err) {
        const res: any = await logoutApi();
        if (res?.data || res?.redirect) {
          window.location.href = res?.data?.redirect || res?.redirect;
          sessionStorage.clear();
          localStorage.clear();
          Cookies.remove("okta_tokens");
        }
      }
    }
    axios.defaults.headers.common["Authorization"] =
      decodeURIComponent(access_token);
  } catch (err) {
    console.error(err);
  }
};

const useApiCall = (
  service: any,
  flag: boolean = false,
  isLogout: boolean = false
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState<any>(null);

  const fetchData = useCallback(async (param: any) => {
    setLoading(true);
    try {
      await getAccessToken();
      resetRefreshTokenInterval();
      const result = await service(param);
      setLoading(false);

      if (result?.status) {
        let backData = result?.data || result?.result;
        if ((result?.status === 204 || result?.status === 301) && !backData) {
          backData = { status: true };
        }
        setData(backData);
        return backData;
      }
    } catch (error: any) {
      setLoading(false);
      setError(error?.message || error?.detail);
      if (isLogout && error?.response?.status === HTTP_ERROR_UNAUTHORIZED) {
        const res: any = await logoutApi();
        if (res?.data || res?.redirect) {
          window.location.href = res?.data?.redirect || res?.redirect;
          sessionStorage.clear();
          localStorage.clear();
          Cookies.remove("okta_tokens");
        }
        return;
      }

      // if (error?.response?.status === HTTP_ERROR_WRITE_ACCESS) {
      //   dispatch({
      //     type: SET_PERMISSION_MODAL,
      //     payload: {
      //       open: true,
      //     },
      //   });
      //   return;
      // }
      // if (error?.response?.status === HTTP_ERROR_BAD_REQUEST) {
      //   dispatch({
      //     type: SET_ERROR_MODAL,
      //     payload: {
      //       open: true,
      //       content: {
      //         code: error?.response?.status,
      //         values: error?.response?.data?.errors?.[0] || error?.response?.data,
      //       },
      //     },
      //   });
      //   return;
      // }
      if (
        !flag &&
        (error?.message || error?.detail?.message || error?.detail)
      ) {
        Toast.error(error?.message || error?.detail?.message || error?.detail);
        return;
      }
    }
  }, []);
  return [fetchData as Function, loading, data, error];
};

export default useApiCall;
