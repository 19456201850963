export const SET_META = "SET_META";
export const SET_TOOLTIP = "SET_TOOLTIP";
export const SET_ADD_PROFILE_MODAL = "SET_ADD_PROFILE_MODAL";
export const SET_ADD_TAG_MODAL = "SET_ADD_TAG_MODAL";
export const SET_ALERT_MODAL = "SET_ALERT_MODAL";
export const SET_LOADING_MODAL = "SET_LOADING_MODAL";

export const SEARCH_RESULT_LIMIT = 30;
export const SEARCH_RESULT_LIMIT_5 = 5;
export const SEARCH_RESULT_LIMIT_10 = 10;

export const STATUS_ACTIVE = 1;
export const STATUS_INACTIVE = 2;
export const STATUS_REMOVED = 0;

export const PUBLISH_STATUS_PUBLISHED = 1;
export const PUBLISH_STATUS_REMOVED = 2;

export const MAX_ONCE_FILE_LIMIT = 4;

export const LEADERSHIP_COUNTRY = 2;

export const MAX_BIO_CHARACTER_COUNT = 25000;

export const COUNTRY_ID = {
  usa: 2,
  canada: 6,
};

export const URLS = {
  HOMEPAGE: "home",
  PROFILE: {
    ROOT: "/profile",
    DETAIL: "/profile/:brokerId",
    FORM: "/profile/:brokerId/edit",
    LOG: "/profile/:brokerId/log",
    PHOTO: "/profile/:brokerId/photo",
    PHOTO_TUTORIAL: "/profile/:brokerId/photo/tutorial",
    PHOTO_GALLERY: "/profile/:brokerId/photo/gallery/:type",
    MANUAL: "/profile/:brokerId/photo/manual",
    IN_ACTIVE: "/profile/:brokerId/inactive",
  },
  METADATA: {
    ROOT: "/metadata",
  },
  LOCATION: {
    ROOT: "/location",
    DETAIL: "/location/:locationId",
    LOG: "/location/:locationId/log",
  },
  USER: {
    ROOT: "/user",
  },
  ACTIVITY: {
    ROOT: "/activity",
  },
};

export const EDITOR_TOOLBAR_OPTIONS = {
  // options: ['inline', 'blockType', 'fontSize']
};

export const HTTP_ERROR_UNAUTHORIZED = 403;

export const COMPLIANCE_DOCUMENT = {
  BIO: "#page=10",
  PROFILE: "#page=8",
  IMAGE: "#page=22",
  BASE_URL:
    "https://jll2.sharepoint.com/teams/ProfileManagement750/Shared%20Documents/Product%20Support/How%20To%20Guides/Profile%20Mgt%20HowTo_final.pdf",
};

export const HEADSHOT_BRAND_URL =
  "https://brandportal.jll.com/bms/category/browse.cfm?category=4428";

export const FACET_TAG_LABELS = ["industry", "service_line"];

export const USER_GROUP_PROFILE_ADMIN = "Support";

export * from "./api";
export * from "./table";
