// src/utils/datadog.ts
import { datadogRum } from "@datadog/browser-rum";

export function initializeDatadog() {
  const clientToken = process.env.REACT_APP_DD_CLIENT_TOKEN;
  const applicationId = process.env.REACT_APP_DD_APPLICATION_ID;
  const env = process.env.REACT_APP_DD_ENV;
  const version = process.env.REACT_APP_DD_VERSION;
  const sessionSampleRate = Number(process.env.REACT_APP_DD_SAMPLE_RATE) || 100;
  const sessionReplaySampleRate = Number(process.env.REACT_APP_DD_REPLAY_SAMPLE_RATE) || 0;
  console.log(process.env.REACT_APP_DD_CLIENT_TOKEN);
  console.log(process.env.REACT_APP_DD_APPLICATION_ID);

  // Only initialize if required environment variables are set
  if (clientToken && applicationId) {
    datadogRum.init({
      applicationId,
      clientToken,
      site: "us3.datadoghq.com",
      service: "broker-profile-management",
      env: env || "dev",
      version: version || "1.0.0",
      sessionSampleRate,
      sessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });

    // console.log("✅ Datadog RUM initialized");
  } 
//   else {
//     console.warn("⚠️ Datadog RUM is not enabled. Missing environment variables.");
//   }
}
