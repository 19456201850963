import { FacetsProps, BucketProps, FilteredFacetProps } from "types";
import { capitalize } from "./text";
import { FACET_TAG_LABELS } from "constant";

export const getFilteredFacets = (facets: FacetsProps) => {
  return Object.keys(facets)
    .filter((key) => key !== "count")
    .map((key) => {
      // @ts-ignore fix ts(7053)
      const buckets: BucketProps[] = facets[key].buckets;

      // Filter Label for Tag
      let label = "";
      if (FACET_TAG_LABELS?.includes(key?.toLowerCase())) {
        label = `${capitalize(key.replaceAll("_", " "))} (Tag)`;
      } else {
        label = capitalize(key.replaceAll("_", " "));
      }
      return {
        id: key,
        label,
        facets: [
          ...buckets.map((facet) => {
            let label = facet?.name || facet.val;
            if (label?.toString()?.toLowerCase() === "removed")
              label = "Inactive";
            if (key === "active_status" && label?.toString() === "1")
              label = "Active";
            if (key === "active_status" && label?.toString() === "2")
              label = "Inactive";
            return {
              count: facet.count,
              label: label,
              value: facet.val,
              id:
                typeof facet?.val === "string"
                  ? `${key}_${facet.val.toLowerCase().replaceAll(" ", "_")}`
                  : `${key}_${facet?.val}`,
            };
          }),
        ],
      };
    });
};

export const getOrderedFacets = (
  facets: FilteredFacetProps[],
  sortList: string[]
) => {
  const sortedFacets = facets.sort((a, b) => {
    return sortList.indexOf(a.id) - sortList.indexOf(b.id);
  });
  return sortedFacets;
};
