import { Route, Routes, useLocation } from "react-router-dom";
import { URLS } from "constant";
import { useContext, useEffect } from "react";
import { GlobalContext } from "context";
import {
  RequiredAuth,
  RequiredGroup,
} from "./RequiredAuth";
import {
  Homepage,
  Profile,
  Metadata,
  AuthHome,
  LocationDetail,
  NoPage,
  OfficeHome,
  Userpage,
  NoPermissionPage,
  ProfileLog,
  ActivityPage,
  LocationLog,
} from "pages";

const RootRoutes = () => {
  const { state } = useContext(GlobalContext);
  const { hideTooltip } = state;

  const location = useLocation();

  useEffect(() => {
    if (hideTooltip) hideTooltip();
  }, [location]);

  return (
    <Routes>
      {/* <Route path="/login/callback" element={<LoginCallback />} /> */}
      <Route path="/" element={<RequiredAuth />}>
        <Route path={`/${URLS.PROFILE.DETAIL}`} element={<Profile />} />
        <Route path={`/${URLS.PROFILE.FORM}`} element={<Profile />} />
        <Route path={`/${URLS.PROFILE.LOG}`} element={<ProfileLog />} />
        <Route path={`/${URLS.PROFILE.PHOTO}`} element={<Profile />} />
        <Route path={`/${URLS.PROFILE.MANUAL}`} element={<Profile />} />
        <Route path={`/${URLS.PROFILE.PHOTO_TUTORIAL}`} element={<Profile />} />
        <Route path={`/${URLS.PROFILE.PHOTO_GALLERY}`} element={<Profile />} />
        <Route path={`/${URLS.PROFILE.IN_ACTIVE}`} element={<Profile />} />

        <Route path="/" element={<RequiredGroup />}>
          {/* For Country Admin */}
          <Route path={`/${URLS.PROFILE.ROOT}`} element={<Homepage />} />
          <Route path={`/`} element={<AuthHome />} />

          <Route path={`/${URLS.METADATA.ROOT}`} element={<Metadata />} />

          <Route path={`/${URLS.LOCATION.ROOT}`} element={<OfficeHome />} />
          <Route
            path={`/${URLS.LOCATION.DETAIL}`}
            element={<LocationDetail />}
          />
          <Route path={`/${URLS.LOCATION.LOG}`} element={<LocationLog />} />

          <Route path={`/${URLS.USER.ROOT}`} element={<Userpage />} />

          <Route path={`/${URLS.ACTIVITY.ROOT}`} element={<ActivityPage />} />
        </Route>
        <Route path="/permission" element={<NoPermissionPage />} />
      </Route>
      {/* <Route path={`/${URLS.ERROR}`} element={<StatusError />} /> */}
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
};

export default RootRoutes;
