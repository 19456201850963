import { SearchOutlined } from "@material-ui/icons";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { SEARCH_RESULT_LIMIT_5 } from "constant";
import { GlobalContext, useNavigationHistory } from "context";
import { useApiCall } from "hooks";
import debounce from "lodash.debounce";
import queryString from "query-string";
import { useContext, useEffect, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getActivityListAPI } from "services";
import { Input } from "ui-atoms";
import {
  ListLeftSide,
  Loading,
  ProfileLogModal,
  StyledActivityValue,
  StyledActivityValueProps,
  Table,
} from "ui-molecules";
import { LayoutDetail } from "ui-organisms";
import { getDecimalFormating } from "utils";

const LOG_TABLE_COLUMNS: {
  id: StyledActivityValueProps["valueKey"];
  label: string;
}[] = [
  {
    id: "change",
    label: "Field Change",
  },
  {
    id: "type_name",
    label: "Status",
  },
  {
    id: "updated_user",
    label: "Modified by",
  },
  {
    id: "update_timestamp",
    label: "Modified on",
  },
];

const ProfileLog = () => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const location = useLocation();
  const { navigate } = useNavigationHistory();
  const [getActivityList] = useApiCall(getActivityListAPI);
  const { brokerId, logId } = useParams();
  const [paramFilters, setParamFilters] = useState<any>(null);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState<string>("");
  const [facets, setFacets] = useState<any>();
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    const filters = queryString.parse(location?.search, {
      arrayFormat: "bracket",
    });
    setParamFilters(filters);
  }, [location]);

  useEffect(() => {
    setPage(1);
    if (!paramFilters) return;
    setIsFirstLoading(true);
    getActivityList({
      object_id: [brokerId],
      page: 1,
      limit: SEARCH_RESULT_LIMIT_5,
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.docs);
        setPage(res?.page);
        setTotal(res?.total);
        setFacets(res?.facets);
      })
      .catch(() => {
        setData([]);
        setTotal(0);
        setPage(1);
      })
      .finally(() => {
        setIsFirstLoading(false);
      });
  }, [brokerId, paramFilters]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    setIsLoading(true);
    getActivityList({
      page: nextPage,
      object_id: [brokerId],
      limit: SEARCH_RESULT_LIMIT_5,
      ...paramFilters,
    })
      .then((res: any) => {
        if (!res) return;
        setData((prevData: any) => [...prevData, ...res?.docs]);
        setPage(res?.page);
        setTotal(res?.total);
        setFacets(res?.facets);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateKeywordFilter = (e: any) => {
    const updatedFilters = { ...paramFilters };
    if (e.target.value) {
      updatedFilters["keyword"] = e.target.value;
    } else if (updatedFilters.hasOwnProperty("keyword")) {
      delete updatedFilters.keyword;
    }
    navigate(
      `${location.pathname}?${queryString.stringify(updatedFilters, {
        arrayFormat: "bracket",
      })}`
    );
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 300),
    [paramFilters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: total > data?.length,
    onLoadMore: loadMore,
  });

  return (
    <>
      <LayoutDetail>
        <ListLeftSide
          facets={facets}
          backUrl={`/profile/${brokerId}`}
          isLoading={isFirstLoading}
        />
        <div className="flex flex-col w-full h-full relative overflow-hidden">
          <div className="flex flex-row items-center pt-6 pb-8 mx-10 border-b border-b-jll-stroke-subdued justify-between flex-wrap">
            <div className="flex flex-row items-center space-x-3"></div>
            <div>
              <Input
                leadingIcon={SearchOutlined}
                isClearable
                onChange={onChangeInput}
                value={keyword}
                className="w-[280px] !mb-0"
                placeholder="Search by name, country, city..."
                label="Search"
                disabled={!data?.length}
              />
            </div>
          </div>

          {!!data?.length && (
            <div className="flex flex-col mx-10 relative">
              <div className="flex flex-row items-center justify-between space-x-4 py-3 border-b border-b-jll-stroke-subdued">
                <span>{`${getDecimalFormating(total)} result${
                  total > 1 ? "s" : ""
                }`}</span>
              </div>
            </div>
          )}

          <div className="flex flex-1 w-full h-full flex-col px-10 overflow-auto">
            {isFirstLoading ? (
              <Table.Loading size={4} />
            ) : (
              <>
                {!!data?.length ? (
                  <>
                    <Table>
                      <Table.Thead>
                        <Table.Tr>
                          {LOG_TABLE_COLUMNS.map((column, idx) => (
                            <Table.Th key={idx}>{column.label}</Table.Th>
                          ))}
                          <Table.Th>Action</Table.Th>
                        </Table.Tr>
                      </Table.Thead>
                      <Table.Tbody>
                        {data?.map((item: any, idx: number) => (
                          <Table.Tr key={idx}>
                            {LOG_TABLE_COLUMNS.map((column, idx1) => (
                              <Table.Td key={idx1}>
                                <StyledActivityValue
                                  activity={item}
                                  valueKey={column.id}
                                />
                              </Table.Td>
                            ))}
                            <Table.Td>
                              <SolButton
                                variant="secondary"
                                onSol-click={() => {
                                  setIsOpen(true);
                                  setSelectedActivity(item);
                                }}
                              >
                                Activity detail
                                <SolIcon icon="arrow_right_alt" />
                              </SolButton>
                            </Table.Td>
                          </Table.Tr>
                        ))}
                      </Table.Tbody>
                    </Table>

                    {!!(total > data?.length) && (
                      <div
                        className="w-full py-2 flex justify-center items-center"
                        ref={sentryRef}
                      >
                        <Loading />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-full h-full flex justify-center items-center text-jll-text-base-subdued">
                    There is not any activity log
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </LayoutDetail>

      <ProfileLogModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        activity={selectedActivity}
      />
    </>
  );
};

export default ProfileLog;
