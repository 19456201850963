import { MAX_BIO_CHARACTER_COUNT } from "constant";
import { OptionProps } from "react-select";

/**
 * Capitalize string
 * @param value Ex: ryong
 * @returns Ex: Ryong
 */
export const capitalize = (s: string | undefined) => {
  if (!s) return "";
  return s[0].toUpperCase() + s.slice(1);
};

/**
 * Capitalize first letters of name
 * @param value Ex: ryong ma
 * @returns Ex: RM
 */
export const capitalizeName = (name: string = "") => {
  const arr = name?.split(" ");
  return `${arr[0]?.[0]?.toUpperCase() || ""}${
    arr[1]?.[0]?.toUpperCase() || ""
  }`;
};

/**
 * Capitalize all words
 * @param value Ex: date_available, _
 * @returns Ex: Date Available
 */
export const capitalizeWords = (
  str: string = "",
  splitLetter: string = " "
) => {
  try {
    const arr = str?.split(splitLetter);
    let newStr = "";
    arr?.forEach((s, idx) => {
      newStr += `${capitalize(s)}`;
      if (idx < arr?.length - 1) newStr += " ";
    });
    return newStr;
  } catch (err) {
    return "";
  }
};

/**
 * Capitalize all words
 * @param value Ex: Date Available
 * @returns Ex: Date available
 */
export const titleToSentenceCase = (title: string) => {
  if (!title) return title; // Handle empty string

  // Convert the first character to uppercase and the rest to lowercase
  return title.charAt(0).toUpperCase() + title.slice(1).toLowerCase();
};

/**
 * Capitalize all words
 * @param value Ex: Flex r&d, &
 * @returns Ex: Flex r&D
 */
export const capitalizeSymbolWords = (
  str: string = "",
  splitLetter: string = "&"
) => {
  try {
    const arr = str?.split(splitLetter);
    let newStr = "";
    if (arr?.length <= 1) return str;
    arr?.forEach((s, idx) => {
      newStr += `${capitalize(s)}`;
      if (idx < arr.length - 1) newStr += splitLetter;
    });
    return newStr;
  } catch (err) {
    return "";
  }
};

/**
 * return the first name from full name
 * @param value Ex: ryong ma
 * @returns Ex: Ryong
 */
export const getFirstnameFromFullname = (name: string = "") => {
  const arr = name?.split(" ");
  return capitalize(arr?.[0]) || "";
};

/**
 * Join 2 strings
 * @param value Ex: building, address
 * @returns Ex: "building, address"
 */
export const joinTwoStr = (
  str1: string = "",
  str2: string = "",
  joinStr: string = ", "
) => {
  const label = [];
  if (str1) {
    label.push(str1);
  }
  if (str2) {
    label.push(str2);
  }
  return label.join(joinStr);
};

/**
 * Returns full address
 * @param value Ex: address, city, state, code
 * @returns Ex: "address city, state code"
 */
export const getFullAddress = (
  address: string = "",
  city: string = "",
  state: string = "",
  code: string = ""
) => {
  const str1 = joinTwoStr(address, city, " ") || "";
  const str2 = joinTwoStr(state, code, " ") || "";
  return joinTwoStr(str1, str2);
};

/**
 * Returns Past Tense
 * @param value Ex: add or update
 * @returns Ex: "added" or "updated"
 */
export const getPastTense = (value: string) => {
  switch (value?.toLowerCase()) {
    case "add":
      return "added";
    default:
      return `${value}d`;
  }
};

export const getOptions = (key: string, meta?: any) => {
  if (!meta?.[key]) return [];
  return meta?.[key]
    .map((item: any) => ({
      value: item?.id,
      label: item?.description || item?.name,
    }))
    .sort((a: OptionProps, b: OptionProps) =>
      a.label?.toString()?.localeCompare(b?.label?.toString())
    );
};

export const parseJSON = (str: string) => {
  try {
    return JSON.parse(str?.replaceAll(/\\054/g, ",")?.replaceAll("'", '"'));
  } catch (err) {
    return str?.replace(/\\054/g, ",");
  }
};

export const parseOriginalJSON = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    return str;
  }
};

export const bioValidation = (bio: string, flag: boolean = true) => {
  const characterCount = bio.length;
  if (characterCount > MAX_BIO_CHARACTER_COUNT) {
    return `This bio exceeds the maximum character count of ${MAX_BIO_CHARACTER_COUNT}. Please shorten the bio.`;
  }

  return "";
};

/**
 * Pick object with specific key
 * @param value Ex: data => { firstName: "XXX", lastName: "XXX" }, str => "first"
 * @returns Ex: {firstName: "XXX"}
 */
export const pickSpecificKeyFromObj = (data: any, str: string) => {
  return Object.keys(data)
    .filter((key) => key.includes(str))
    ?.reduce((obj, key) => {
      return Object.assign(obj, { [key]: data[key] });
    }, {});
};

export const joinArray = (array: string[], symbol: string) => {
  const result = (array?.filter((item: any) => item) || []).join(symbol);
  return result;
};
