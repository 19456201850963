import { SearchOutlined } from "@material-ui/icons";
import { SolButton, SolIcon } from "@solstice/sol-react";
import {
  SEARCH_RESULT_LIMIT,
  SET_ADD_PROFILE_MODAL,
  SET_LOADING_MODAL,
  URLS,
} from "constant";
import { GlobalContext, useNavigationHistory } from "context";
import { useApiCall } from "hooks";
import debounce from "lodash.debounce";
import { useContext, useEffect, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate } from "react-router-dom";
import { getUserListAPI, postBrokerAPI } from "services";
import { UserProps } from "types";
import { Input } from "ui-atoms";
import { Loading, Modal, StyledListValue, Table } from "ui-molecules";
import { getDecimalFormating } from "utils";

interface IAddProfileModal {}

const COLUMNS: { id: keyof UserProps | "country_label"; label: string }[] = [
  {
    id: "displayName",
    label: "Name",
  },
  {
    id: "title",
    label: "Job Title",
  },
  {
    id: "country_label",
    label: "Country",
  },
  {
    id: "userLanguage",
    label: "Language",
  },
  // {
  //   id: "",
  //   label: "Action",
  // },
];

const AddProfileModal: React.FC<IAddProfileModal> = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { isAddProfileModalOpen } = state;
  const { navigate } = useNavigationHistory();
  const [getUserList, isLoading] = useApiCall(getUserListAPI);
  const [postBroker] = useApiCall(postBrokerAPI);
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState<UserProps[]>([]);
  const [pagination, setPagination] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [filters, setFilters] = useState<any>(null);

  useEffect(() => {
    if (!isAddProfileModalOpen) return;
    setFilters({});
    setKeyword("");
    setData([]);
    setPagination(null);
  }, [isAddProfileModalOpen]);

  useEffect(() => {
    if (!filters?.keyword?.length) {
      setData([]);
      setPagination(null);
      return;
    }
    setIsFirstLoading(true);
    getUserList({
      page: 1,
      limit: SEARCH_RESULT_LIMIT,
      keyword: filters?.keyword,
    })
      .then((res: any) => {
        if (!res) return;
        setData(res?.data);
        setPagination(res?.pagination);
        setPage(1);
      })
      .catch(() => {
        setData([]);
        setPagination(null);
      })
      .finally(() => {
        setIsFirstLoading(false);
      });
  }, [filters]);

  const loadMore = () => {
    if (!filters?.keyword?.length) {
      setData([]);
      setPagination(null);
      return;
    }
    const nextPage = page + 1;
    setPage(nextPage);
    getUserList({
      page: nextPage,
      limit: SEARCH_RESULT_LIMIT,
      keyword: filters?.keyword,
    })
      .then((res: any) => {
        if (!res) return;
        setData((prevData: any) => [...prevData, ...res?.data]);
        setPagination(res?.pagination);
      })
      .catch(() => {
        setData([]);
        setPagination(null);
      });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: pagination?.totalRows > data?.length,
    onLoadMore: loadMore,
  });

  const updateKeywordFilter = (e: any) => {
    setFilters({ keyword: e.target.value });
  };

  const debounceUpdateKeyword = useMemo(
    () => debounce(updateKeywordFilter, 500),
    [filters]
  );

  const onChangeInput = (e: any) => {
    setKeyword(e.target.value);
    debounceUpdateKeyword(e);
  };

  const addUser = (user: any) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Adding a new profile",
        },
      });
      postBroker({ broker_user_json: user })
        .then((res: any) => {
          if (!res) return;
          dispatch({
            type: SET_ADD_PROFILE_MODAL,
            payload: false,
          });
          navigate(`${URLS.PROFILE.ROOT}/${res?.id}/edit?tab=general`);
        })
        .finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
        });
    } catch (err) {}
  };

  const handleClose = () => {
    dispatch({
      type: SET_ADD_PROFILE_MODAL,
      payload: false,
    });
  };

  return (
    <Modal isOpen={isAddProfileModalOpen} setIsOpen={handleClose} size="large" className="!p-0">
      <Modal.Header className="p-6 pb-0">
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl">Add a profile</h2>
            <p>
              Search JLL’s active directory for current employees
            </p>
          </div>
          <SolButton variant="tertiary" onSol-click={handleClose}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body className="!overflow-hidden flex flex-col p-6 pt-0">
        <div className="mt-6 flex flex-col">
          <Input
            label="Search"
            leadingIcon={SearchOutlined}
            isClearable
            className="mb-5"
            value={keyword}
            onChange={onChangeInput}
            placeholder="Search by name, country, city..."
          />
          {!!keyword?.length && !!pagination?.totalRows && (
            <p className="text-xl mb-7">{`${getDecimalFormating(
              pagination?.totalRows
            )} result${pagination?.totalRows > 1 ? "s":""}`}</p>
          )}
        </div>
        <div className="h-full overflow-y-auto -mx-6 px-6">
          {isFirstLoading ? (
            <Table.Loading />
          ) : (
            <>
              {!!keyword?.length && (
                <>
                  <Table>
                    <Table.Thead>
                      <Table.Tr>
                        {COLUMNS?.map((column, idx) => (
                          <Table.Th key={idx}>{column.label}</Table.Th>
                        ))}
                        <Table.Th>Action</Table.Th>
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      {data?.map((item: UserProps, idx: number) => (
                        <Table.Tr key={idx}>
                          {COLUMNS?.map((column, idx1) => (
                            <Table.Td key={idx1} className="!whitespace-normal">
                              <StyledListValue
                                broker={item}
                                valueKey={column.id}
                              />
                            </Table.Td>
                          ))}
                          <Table.Td>
                            {!item?.broker ? (
                              <SolButton onSol-click={() => addUser(item)}>
                                Add profile
                                <SolIcon icon="add" />
                              </SolButton>
                            ) : (
                              <SolButton
                                variant="secondary"
                                onSol-click={() => {
                                  dispatch({
                                    type: SET_ADD_PROFILE_MODAL,
                                    payload: false,
                                  });
                                  if (!!item?.broker?.profiles?.length)
                                    navigate(
                                      `${URLS.PROFILE.ROOT}/${item?.broker?.id}?tab=${item?.broker?.profiles?.[0]?.id}`
                                    );
                                  else
                                    navigate(
                                      `${URLS.PROFILE.ROOT}/${item?.broker?.id}/edit?tab=general`
                                    );
                                }}
                              >
                                Go to profile
                                <SolIcon icon="arrow_right_alt" />
                              </SolButton>
                            )}
                          </Table.Td>
                        </Table.Tr>
                      ))}
                    </Table.Tbody>
                  </Table>

                  {(!!(pagination?.totalRows > data?.length) || isLoading) && (
                    <div
                      className="w-full py-2 flex justify-center items-center"
                      ref={sentryRef}
                    >
                      <Loading />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddProfileModal;
