import { format } from "date-fns";

/**
 * Get full formated date
 * Build with Intl. For more information about Intl please go to
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param value Ex: 2023-02-18T21:52:16.381000Z
 * @param options Ex: { dateStyle: "long" }
 * @returns Ex: March 20, 2023 at 2:26 PM
 */
export const getFormatedDateTime = (value?: string, options?: any) => {
  const defaultOption: any = {
    dateStyle: "long",
    timeStyle: "short",
  };
  try {
    const mydate = value ? new Date(value) : new Date();
    return new Intl.DateTimeFormat("en-US", options || defaultOption).format(
      mydate
    );
  } catch (err) {
    return "";
  }
};

export const getFormatedDate = (value?: string) => {
  try {
    const mydate = value ? new Date(value) : new Date();
    return format(mydate, "dd MMM yyyy");
  } catch (err) {
    return "";
  }
};

export const getUTCDate = (value: string = "") => {
  let date = new Date()?.toLocaleString("en-US", { timeZone: "UTC" });
  if (value)
    date = new Date(value)?.toLocaleString("en-US", { timeZone: "UTC" });
  const result = new Date(date);
  return result;
};
