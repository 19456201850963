import { Modal } from "ui-molecules";
import cn from "classnames";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { useContext } from "react";
import { GlobalContext } from "context";
import { SET_ALERT_MODAL } from "constant";

const AlertModal = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { alertModal } = state;

  const handleClose = () => {
    dispatch({
      type: SET_ALERT_MODAL,
      payload: {
        open: false,
        kind: "",
        title: "",
        description: "",
        icon: "",
        btn1: null,
        btn2: null,
        btn3: null,
      },
    });
  };

  return (
    <Modal isOpen={alertModal?.open} setIsOpen={handleClose}>
      <Modal.Body>
        <div className="flex flex-row space-x-4">
          {(alertModal?.kind || alertModal?.icon) && (
            <div
              className={cn(
                "rounded-full min-w-[56px] max-h-[56px] min-h-[56px] flex justify-center items-center",
                {
                  "bg-jll-surface-rag-success bg-opacity-20":
                    alertModal?.kind === "success",
                  "bg-jll-surface-rag-warning bg-opacity-10":
                    alertModal?.kind === "warn",
                  "bg-jll-surface-rag-danger bg-opacity-5":
                    alertModal?.kind === "error",
                }
              )}
            >
              <SolIcon
                icon={alertModal?.icon}
                size="32"
                className={cn("", {
                  "text-jll-icon-rag-success": alertModal?.kind === "success",
                  "text-jll-icon-rag-warning": alertModal?.kind === "warn",
                  "text-jll-icon-rag-danger": alertModal?.kind === "error",
                })}
              />
            </div>
          )}
          <div>
            <p className="text-xl mb-1">{alertModal?.title}</p>
            {alertModal?.description && <p>{alertModal?.description}</p>}
            <div
              dangerouslySetInnerHTML={{
                __html: alertModal?.html,
              }}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3">
          {alertModal?.btn1 && (
            <SolButton
              variant="secondary"
              className="!min-w-[96px] sol-w-full"
              onSol-click={() => alertModal?.btn1?.onClick()}
            >
              {alertModal?.btn1.label}
            </SolButton>
          )}
          {alertModal?.btn3 && (
            <SolButton
              className="!min-w-[96px] sol-w-full"
              onSol-click={() => alertModal?.btn3?.onClick()}
            >
              {alertModal?.btn3.label}
            </SolButton>
          )}
          {alertModal?.btn2 && (
            <SolButton
              className="min-w-[96px] sol-w-full whitespace-nowrap"
              onSol-click={() => alertModal?.btn2?.onClick()}
            >
              {alertModal?.btn2.label}
            </SolButton>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
