import {
  SolButton,
  SolIcon,
  SolIconBubble,
  SolTextField,
} from "@solstice/sol-react";
import { COUNTRY_ID, SEARCH_RESULT_LIMIT, STATUS_ACTIVE } from "constant";
import { GlobalContext } from "context";
import { useApiCall, useSession } from "hooks";
import { useContext, useEffect, useState } from "react";
import {
  getCountryAPI,
  getStateAPI,
  patchCityAPI,
  patchCountryAPI,
  patchIndustryAPI,
  patchMarketAPI,
  patchServiceAPI,
  patchStateAPI,
} from "services";
import { SelectAutoComplete, Switch, Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import { capitalize } from "utils";

interface EditTagModalProps {
  isOpen: boolean;
  setIsOpen: (flag: boolean) => void;
  kind: "industry" | "service" | "country" | "state" | "city" | "market";
  selectedItem?: any;
  loadData?: any;
}

const EditTagModal: React.FC<EditTagModalProps> = ({
  isOpen,
  setIsOpen,
  kind,
  selectedItem,
  loadData,
}) => {
  const { session } = useSession();
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [patchIndustry] = useApiCall(patchIndustryAPI);
  const [patchService] = useApiCall(patchServiceAPI);
  const [patchCountry] = useApiCall(patchCountryAPI);
  const [patchState] = useApiCall(patchStateAPI);
  const [patchCity] = useApiCall(patchCityAPI);
  const [patchMarket] = useApiCall(patchMarketAPI);
  const [getCountry] = useApiCall(getCountryAPI);
  const [getState] = useApiCall(getStateAPI);
  const [tagName, setTagName] = useState("");
  const [isBlur, setIsBlur] = useState(false);
  const [isRepublish, setIsRepublish] = useState(false);

  const country_id_usa =
    meta?.country?.find(
      (item: any) => item?.name?.toLowerCase() === "united states"
    )?.id || COUNTRY_ID?.usa;
  const country_id_canada =
    meta?.country?.find((item: any) => item?.name?.toLowerCase() === "canada")
      ?.id || COUNTRY_ID?.canada;

  const [countryValue, setCountryValue] = useState<any>({
    value: undefined,
    touched: false,
  });
  const [stateValue, setStateValue] = useState<any>({
    value: undefined,
    touched: false,
  });
  const [regionValue, setRegionValue] = useState<any>({
    value: undefined,
    touched: false,
  });

  const isState =
    ((kind === "city" || kind === "market") &&
      (countryValue?.value as any)?.value === country_id_usa) ||
    (countryValue?.value as any)?.value === country_id_canada;

  const regionOptions = Object.keys(meta?.["region"] || [])?.map(
    (key: number | string) => ({
      value: Number(key),
      label: meta?.["region"]?.[key],
    })
  );

  useEffect(() => {
    if (!isOpen || !selectedItem) return;
    setTagName(selectedItem?.name);
    setIsBlur(false);

    setCountryValue({
      value: selectedItem?.country_id
        ? { label: selectedItem?.country_name, value: selectedItem?.country_id }
        : undefined,
      touched: false,
    });

    setStateValue({
      value: selectedItem?.state_id
        ? { label: selectedItem?.state_name, value: selectedItem?.state_id }
        : undefined,
      touched: false,
    });

    setRegionValue({
      value:
        regionOptions?.find((item) => item?.value === selectedItem?.region) ||
        undefined,
      touched: false,
    });

    return () => {
      setCountryValue({
        value: undefined,
        touched: false,
      });
      setStateValue({
        value: undefined,
        touched: false,
      });
      setRegionValue({
        value: undefined,
        touched: false,
      });
    };
  }, [isOpen, selectedItem]);

  const handleSubmit = () => {
    try {
      let API;
      let payload: any = {
        name: tagName,
        pk: selectedItem?.pk,
        republish: isRepublish,
        updated_user: session?.id,
      };
      switch (kind) {
        case "service":
          API = patchService;
          break;
        case "country":
          API = patchCountry;
          break;
        case "state":
          API = patchState;
          break;
        case "city":
          API = patchCity;
          if (isState) {
            payload = {
              ...payload,
              country: (countryValue?.value as any)?.value,
              state: (stateValue?.value as any)?.value,
            };
          } else {
            payload = {
              ...payload,
              country: (countryValue?.value as any)?.value,
            };
          }
          break;
        case "market":
          API = patchMarket;
          payload = {
            ...payload,
            region: (regionValue?.value as any)?.value,
            country: (countryValue?.value as any)?.value,
            state: (stateValue?.value as any)?.value || null,
          };
          break;
        default:
          API = patchIndustry;
      }
      API(payload).then((res: any) => {
        setIsOpen(false);
        loadData();
        if (!!res)
          Toast.success(
            `Successfully edited "${selectedItem?.name}" to "${tagName}"`
          );
      });
    } catch (err) {}
  };

  const loadCountryOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return getCountry({
      keyword,
      page,
      limit: SEARCH_RESULT_LIMIT,
      active_status: [STATUS_ACTIVE],
      region:
        // @ts-ignore
        kind === "market" ? [(regionValue?.value as any)?.value] || [] : [],
      sort: "name",
      direction: "asc",
    }).then((res: any) => {
      return {
        options: res?.docs?.map((country: any) => {
          return {
            value: country.pk,
            label: country?.name,
          };
        }),
        hasMore: page < res?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const loadStateOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return getState({
      keyword,
      page,
      limit: SEARCH_RESULT_LIMIT,
      country: [(countryValue?.value as any)?.value],
      active_status: [STATUS_ACTIVE],
      sort: "name",
      direction: "asc",
    }).then((res: any) => {
      return {
        options: res?.docs?.map((state: any) => {
          return {
            value: state.pk,
            label: state?.name,
          };
        }),
        hasMore: page < res?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="default">
      <Modal.Header>
        <div className="flex flex-row justify-between items-start">
          <SolIconBubble icon="edit_square" size="56" variant="danger" />
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="mt-4">
          <h3 className="text-xl mb-6">Edit {capitalize(kind)}</h3>

          <div className="flex flex-col space-y-6">
            {kind === "market" && (
              <SelectAutoComplete
                placeholder="Region"
                label="Region"
                size="sm"
                options={regionOptions}
                required
                name="region"
                value={regionValue?.value}
                onChange={(e) => {
                  setRegionValue((prev: any) => ({ ...prev, value: e }));
                }}
                onBlur={() =>
                  setRegionValue((prev: any) => ({ ...prev, touched: true }))
                }
                error={
                  regionValue?.touched
                    ? regionValue?.value
                      ? undefined
                      : "This field is required"
                    : undefined
                }
                key={`region_${JSON.stringify(regionValue?.value)}`}
                className="!mb-0"
              />
            )}

            {(kind === "city" || (kind === "market" && regionValue?.value)) && (
              <SelectAutoComplete
                label="Country"
                required
                size="sm"
                loadOptions={loadCountryOptions}
                value={countryValue?.value}
                onChange={(e) => {
                  setCountryValue((prev: any) => ({ ...prev, value: e }));
                }}
                onBlur={() =>
                  setCountryValue((prev: any) => ({ ...prev, touched: true }))
                }
                error={
                  countryValue?.touched
                    ? countryValue?.value
                      ? undefined
                      : "This field is required"
                    : undefined
                }
                name="country"
                key={`country_${JSON.stringify(countryValue?.value)}`}
                className="!mb-0"
              />
            )}

            {isState && (
              <SelectAutoComplete
                label="State"
                required
                size="sm"
                className="!mb-0"
                loadOptions={loadStateOptions}
                value={stateValue?.value}
                onChange={(e) => {
                  setStateValue((prev: any) => ({ ...prev, value: e }));
                }}
                onBlur={() =>
                  setStateValue((prev: any) => ({ ...prev, touched: true }))
                }
                error={
                  stateValue?.touched
                    ? stateValue?.value
                      ? undefined
                      : "This field is required"
                    : undefined
                }
                name="state"
                key={`state_${JSON.stringify(stateValue?.value)}`}
              />
            )}

            <SolTextField
              label={`${capitalize(kind)} name`}
              required
              // placeholder={`${kind} name`}
              size="small"
              onSol-input={(e) => {
                setTagName(e?.detail as string);
              }}
              onSol-blur={() => setIsBlur(true)}
              value={tagName}
              errorLabel={
                isBlur && !tagName?.length
                  ? "This field is required"
                  : undefined
              }
            />

            <div className="flex flex-row justify-between items-center w-full">
              <div>
                <div className="flex flex-row items-center space-x-3">
                  <span>Republish for all Users</span>
                  <div className="py-2 px-3 bg-jll-surface-rag-warning bg-opacity-10 rounded-full flex flex-row items-center space-x-2 w-fit">
                    <SolIcon
                      icon="group"
                      className="text-jll-surface-rag-warning-1"
                      size="16"
                    />
                    <span className="text-sm text-jll-icon-rag-warning">
                      {`${selectedItem?.count || 0} user${
                        selectedItem?.count > 1 ? "s" : ""
                      }`}
                    </span>
                  </div>
                </div>
                <p className="text-jll-text-base-subdued">
                  Currently {selectedItem?.count} user’s are associated with
                  this {capitalize(kind)}
                </p>
              </div>

              <Switch
                enabled={isRepublish}
                onChange={(flag: any) => {
                  setIsRepublish(flag);
                }}
                className="mb-0"
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row w-full space-x-3 mt-6 justify-end">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Cancel
          </SolButton>
          <SolButton
            className="!w-[96px] sol-w-full"
            disabled={!tagName?.length}
            onSol-click={() => handleSubmit()}
          >
            Save
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTagModal;
