export * from "./facet";
export * from "./text";
export * from "./format";
export * from "./dateTime";
export * from "./order";
export * from "./form";

export const noPropagation = (e: any) => e.stopPropagation();

// Array 1 - checking array, Array 2 - total Array
export const isArrayContained = (array1: any[], array2: any[]) => {
  return array1.every((item) => array2.includes(item));
};
