import { ArrowBackOutlined } from "@material-ui/icons";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { COUNTRY_ID, SEARCH_RESULT_LIMIT, STATUS_ACTIVE } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  getCityAPI,
  getCountryAPI,
  getMarketAPI,
  getStateAPI,
  patchProfileAPI,
} from "services";
import { BrokerProps, OptionProps, ProfileProps } from "types";
import { SelectAutoComplete } from "ui-atoms";
import { LoadingPage } from "ui-molecules";
import { getApiPayloadFromForm, getOptions, joinArray } from "utils";
import * as Yup from "yup";

interface GeographyProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
  broker: BrokerProps | null;
  setBroker?: any;
}

const INITIAL_VALUES = {
  country: undefined,
  language: undefined,
  states: [],
  cities: [],
  markets: [],
};

const validationSchema = Yup.object().shape({
  country: Yup.object().required("This field is required"),
  language: Yup.string().required("This field is required"),
  markets: Yup.array().required("This field is required"),
});

const Geography: React.FC<GeographyProps> = ({
  profile,
  setProfile,
  isLoading,
  setBroker,
  broker,
}) => {
  const { state } = useContext(GlobalContext);
  const { meta } = state;
  const [searchParams, setSearchParams] = useSearchParams();
  const [getCountry] = useApiCall(getCountryAPI);
  const [getState] = useApiCall(getStateAPI);
  const [getCity] = useApiCall(getCityAPI);
  const [getMarket] = useApiCall(getMarketAPI);
  const [patchProfile] = useApiCall(patchProfileAPI);
  const [languageOptions, setLanguageOptions] = useState<OptionProps[]>([]);

  const country_id_usa =
    meta?.country?.find(
      (item: any) => item?.name?.toLowerCase() === "united states"
    )?.id || COUNTRY_ID?.usa;
  const country_id_canada =
    meta?.country?.find((item: any) => item?.name?.toLowerCase() === "canada")
      ?.id || COUNTRY_ID?.canada;

  const { setFieldValue, values, setValues, touched, errors, handleBlur } =
    useFormik({
      initialValues: INITIAL_VALUES,
      validationSchema: validationSchema,
      onSubmit: async () => {},
    });

  useEffect(() => {
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab"))
      return;
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "country") {
        if (profile?.country)
          formValues = {
            ...formValues,
            [key]: {
              label: profile?.country?.name,
              value: profile?.country?.id,
            },
          };
        return;
      }

      if (key === "cities" || key === "states") {
        let options: any = [];
        if (!!profile?.[key]?.length) {
          options = profile?.[key]?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        formValues = {
          ...formValues,
          [key]: options,
        };
        return;
      }
      if (key === "markets") {
        let options: any = [];
        if (!!profile?.markets?.length) {
          options = profile?.markets?.map((item: any) => ({
            label: item?.name,
            value: item?.id,
          }));
        }
        formValues = {
          ...formValues,
          [key]: options,
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (profile as any)?.[key] ? (profile as any)?.[key] : null,
      };
    });

    // if (profile?.country && !profile?.language) {
    //   const match = meta?.country_language_map?.find(
    //     (item: any) =>
    //       item?.id?.toString() === (profile?.country as any)?.value?.toString()
    //   );
    //   if (match && !!match?.languages?.length) {
    //     formValues = {
    //       ...formValues,
    //       language: {
    //         label: match?.languages?.[0]?.description,
    //         value: match?.languages?.[0]?.id,
    //       },
    //     };
    //   } else {
    //     formValues = {
    //       ...formValues,
    //       language: {
    //         label: "English (United States)",
    //         value: 2,
    //       },
    //     };
    //   }
    // }
    // if (profile?.cities && !profile?.states?.length) {
    //   const matchArr =
    //     profile?.cities?.map((city: any) => ({
    //       label: city?.state_name,
    //       value: city?.state,
    //     })) || [];
    //   formValues = {
    //     ...formValues,
    //     states: matchArr,
    //   };
    // }

    setValues({
      ...formValues,
    });
  }, [profile]);

  useEffect(() => {
    if (!values?.country) {
      setLanguageOptions([]);
    } else {
      const matchOption = meta?.["country_language_map"]?.find(
        (item: any) =>
          item?.id?.toString() === (values?.country as any)?.value?.toString()
      );
      if (!!matchOption?.languages?.length) {
        const lang = matchOption?.languages?.map((item: any) => ({
          label: item?.description || item?.name,
          value: item?.id,
        }));
        setLanguageOptions(lang);

        if (!lang?.find((item: any) => item?.value === values?.language)) {
          setFieldValue("language", "");
        }
      } else {
        setLanguageOptions([{ label: "English (United States)", value: 2 }]);
        if (values?.language !== 2) {
          setFieldValue("language", "");
        }
      }
    }
  }, [values?.country]);

  const loadCountryOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return Promise.all([
      profile?.country &&
        getCountry({
          ids: [profile?.country?.id],
          active_status: [STATUS_ACTIVE],
          sort: "name",
          direction: "asc",
        }),
      getCountry({
        keyword,
        page,
        limit: SEARCH_RESULT_LIMIT,
        active_status: [STATUS_ACTIVE],
        sort: "name",
        direction: "asc",
      }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return {
        options: combinedArray?.map((country: any) => {
          return {
            value: country.pk,
            label: country?.name,
          };
        }),
        hasMore: page < (res_list?.[1] || res_list?.[0])?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const loadMarketOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return Promise.all([
      !!profile?.markets?.length &&
        getMarket({
          ids: profile?.markets?.map((market) => market?.id) || [],
          active_status: [STATUS_ACTIVE],
          sort: "name",
          direction: "asc",
        }),
      getMarket({
        keyword,
        page,
        limit: SEARCH_RESULT_LIMIT,
        active_status: [STATUS_ACTIVE],
        sort: "name",
        direction: "asc",
      }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return {
        options: combinedArray?.map((market: any) => {
          return {
            value: market.pk,
            label: joinArray([market?.country_name, market?.name], " | "),
          };
        }),
        hasMore: page < (res_list?.[1] || res_list?.[0])?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const loadStateOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return Promise.all([
      !!profile?.states?.length &&
        getState({
          ids: profile?.states?.map((city: any) => Number(city?.id)) || [],
          country: [(values?.country as any)?.value],
          active_status: [STATUS_ACTIVE],
          sort: "name",
          direction: "asc",
        }),
      getState({
        keyword,
        page,
        limit: SEARCH_RESULT_LIMIT,
        country: [(values?.country as any)?.value],
        active_status: [STATUS_ACTIVE],
        sort: "name",
        direction: "asc",
      }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return {
        options: combinedArray?.map((state: any) => {
          return {
            value: state?.pk || state?.id,
            label: state?.name,
          };
        }),
        hasMore: page < (res_list?.[1] || res_list?.[0])?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const loadCityOptions = async (
    keyword: string,
    loadedOptions: any,
    { page }: any
  ) => {
    return Promise.all([
      !!profile?.cities?.length &&
        getCity({
          ids: profile?.cities?.map((city: any) => Number(city?.id)) || [],
          country: [(values?.country as any)?.value],
          state: profile?.states?.map((city: any) => Number(city?.id)) || [],
          active_status: [STATUS_ACTIVE],
          sort: "name",
          direction: "asc",
        }),
      getCity({
        keyword,
        page,
        limit: SEARCH_RESULT_LIMIT,
        country: [(values?.country as any)?.value],
        state: profile?.states?.map((city: any) => Number(city?.id)) || [],
        active_status: [STATUS_ACTIVE],
        sort: "name",
        direction: "asc",
      }),
    ]).then((res_list) => {
      const combinedArray = [
        ...(res_list?.[0]?.docs || []),
        ...res_list?.[1]?.docs.filter(
          (obj2: any) =>
            !(res_list?.[0]?.docs || []).some(
              (obj1: any) => obj1.pk === obj2.pk
            )
        ),
      ];
      return {
        options: combinedArray?.map((city: any) => {
          return {
            value: city?.pk || city?.id,
            label: city?.name,
          };
        }),
        hasMore: page < (res_list?.[1] || res_list?.[0])?.num_pages,
        additional: { page: page + 1 },
      };
    });
  };

  const handleUpdate = (
    key: "country" | "cities" | "language" | "markets" | "states",
    values: any
  ) => {
    try {
      setFieldValue(key, values);
      let value;
      switch (key) {
        case "country":
          value = (values as any)?.value;
          break;

        case "cities":
        case "states":
          value = values ? [(values as any)?.value] : [];
          break;

        case "markets":
          value = (values || [])?.map((item: any) => item?.value);
          break;

        default:
          value = values || "";
      }

      if ((key === "country" || key === "language") && !value) {
        return;
      }
      let payload = getApiPayloadFromForm(profile, { [key]: value });
      if (!payload || !Object.keys(payload)?.length) return;
      patchProfile({
        pk: profile?.id,
        ...payload,
      }).then((res: any) => {
        if (!res) return;
        const newResult =
          key === "language"
            ? {
                ...profile,
                [key]: res?.[key],
                language_name: res?.language_name,
              }
            : {
                ...profile,
                [key]: res?.[key],
              };
        setProfile(newResult);

        if (key === "country" || key === "language") {
          let newProfiles = [...(broker?.profiles || [])];
          const index = (broker?.profiles || [])?.findIndex(
            (item) => item?.id === profile?.id
          );
          if (index > -1) {
            newProfiles[index] =
              key === "language"
                ? {
                    ...newProfiles[index],
                    // @ts-ignore
                    [key]: res?.[key],
                    language_name: res?.language_name,
                  }
                : {
                    ...newProfiles[index],
                    [key]: res?.[key],
                  };
            setBroker({ ...broker, profiles: newProfiles });
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="mb-12">
            <h2 className="text-xl mb-1">Geography</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Geography and market for this professional. This information will
              display on your public profile.
            </p>
          </div>
          <form>
            <div className="flex flex-row items-center mb-10 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
              <SelectAutoComplete
                name="language"
                label="Preferred Language"
                required
                className="col-span-1 !mb-0"
                size="sm"
                options={languageOptions}
                value={
                  languageOptions?.find(
                    (item: any) => item?.value === values?.language
                  ) || undefined
                }
                onChange={(e) => {
                  handleUpdate("language", e?.value);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                }}
                error={
                  touched.language ? (errors.language as string) : undefined
                }
                key={`lang_${JSON.stringify(values?.country)}`}
              />
              <SelectAutoComplete
                label="Market"
                className="col-span-1 !mb-0"
                size="sm"
                name="markets"
                loadOptions={loadMarketOptions}
                isMulti
                value={values?.markets}
                onChange={(e) => {
                  handleUpdate("markets", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                }}
                error={touched.markets ? (errors.markets as string) : undefined}
              />
            </div>
            <div className="flex flex-row items-center mb-10 grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10">
              <SelectAutoComplete
                label="Country"
                required
                className="col-span-1 !mb-0"
                size="sm"
                loadOptions={loadCountryOptions}
                value={values?.country}
                name="country"
                onChange={(e) => {
                  handleUpdate("country", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                }}
                error={touched.country ? (errors.country as string) : undefined}
              />

              {((values?.country as any)?.value === country_id_canada ||
                (values.country as any)?.value === country_id_usa) && (
                <SelectAutoComplete
                  label="State"
                  className="col-span-1 !mb-0"
                  size="sm"
                  loadOptions={loadStateOptions}
                  value={values?.states}
                  name="states"
                  onChange={(e) => {
                    handleUpdate("states", e);
                  }}
                  onBlur={(e: any) => {
                    handleBlur(e);
                  }}
                  error={touched.states ? (errors.states as string) : undefined}
                  key={JSON.stringify(values?.country)}
                  cacheOptions
                />
              )}

              <SelectAutoComplete
                name="city"
                label="City"
                className="col-span-1 !mb-0"
                size="sm"
                loadOptions={loadCityOptions}
                // isDisabled={!cityOptions?.length}
                value={values?.cities}
                onChange={(e) => {
                  handleUpdate("cities", e);
                }}
                onBlur={(e: any) => {
                  handleBlur(e);
                }}
                error={touched.cities ? (errors.cities as string) : undefined}
                key={`${JSON.stringify(values?.country)}-${JSON.stringify(
                  values?.cities
                )}`}
                cacheOptions
              />
            </div>
          </form>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "bio");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "markets");
                setSearchParams(searchParams);
              }}
            >
              Next
              <SolIcon icon="arrow_right_alt" />
            </SolButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Geography;
