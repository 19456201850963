import { LazyLoadImage } from "react-lazy-load-image-component";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { AIImageProps, BrokerProps } from "types";
import React, { useContext, useState } from "react";
import { useApiCall } from "hooks";
import { patchBrokerAPI } from "services";
import { ViewPhotoModal } from "ui-molecules";
import { GlobalContext } from "context";
import { SET_LOADING_MODAL } from "constant";
import { Toast } from "ui-atoms";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";

interface PhotoGalleryProps {
  broker: BrokerProps | null;
  setBroker: any;
}

interface GalleryItemProps {
  item: AIImageProps | string;
  broker: BrokerProps | null;
  setBroker: any;
  type: "photos" | "ai_images";
}

const GalleryItem: React.FC<GalleryItemProps> = ({
  item,
  broker,
  setBroker,
  type,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [patchBroker] = useApiCall(patchBrokerAPI);
  const [isOpen, setIsOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isHover, setIsHover] = useState(false);

  const setPrimaryImg = async (url: string) => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        title: "Setting a primary photo",
      },
    });
    const newArr =
      [...(broker?.photos || [])]?.filter((item) => item !== url) || [];

    patchBroker({
      pk: broker?.pk || broker?.id,
      photos: [url, ...newArr],
    })
      .then((res: any) => {
        if (!res) return;
        setBroker(res);
        Toast.success("Profile photo successfully updated!");
      })
      .finally(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: "",
          },
        });
      });
  };

  const handleDownload = async (imageUrl: string) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Downloading a photo",
        },
      });
      const response = await fetch(imageUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      // Ensure the response is okay
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }

      // Convert response into a Blob
      const blob = await response.blob();

      // Create a temporary URL for the Blob
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = imageUrl.split("/").pop() || "image.png";
      link.click();
      link.remove();
    } catch (err) {
    } finally {
      setTimeout(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: null,
          },
        });
      }, 100);
    }
  };

  return (
    <>
      <div className="col-span-1 flex flex-col relative">
        <LazyLoadImage
          src={typeof item === "string" ? item : item?.image}
          className="rounded-lg mb-6 aspect-[886/1040] object-cover hover:shadow-gallery cursor-pointer"
          onClick={() => {
            setImageUrl(typeof item === "string" ? item : item?.image);
            setIsOpen(true);
          }}
          onMouseOver={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        />
        <div>
          <div className="flex flex-row items-center justify-between mb-6">
            <SolButton
              size="small"
              variant="secondary"
              className="whitespace-nowrap"
              onSol-click={() =>
                setPrimaryImg(typeof item === "string" ? item : item?.image)
              }
              disabled={
                broker?.photos?.[0] ===
                (typeof item === "string" ? item : item?.image)
              }
            >
              Use as primary
            </SolButton>
            <SolButton
              size="small"
              variant="outlined"
              onSol-click={() =>
                handleDownload(typeof item === "string" ? item : item?.image)
              }
            >
              <SolIcon icon="download" />
            </SolButton>
          </div>
        </div>
        {broker?.photos?.[0] ===
          (typeof item === "string" ? item : item?.image) && (
          <div className="w-[26px] h-[26px] border-2 border-white rounded-full absolute left-2.5 top-3 bg-jll-icon-rag-success-2 flex justify-center items-center">
            <SolIcon icon="check" className="text-white font-bold" size="20" />
          </div>
        )}
        {isHover && (
          <div className="w-8 h-8 min-w-8 min-h-8 absolute top-1.5 right-1.5 bg-white rounded-full p-1.5 flex justify-center items-center shadow-gallery">
            <SolIcon icon="open_in_full" size="20" />
          </div>
        )}
      </div>
      <ViewPhotoModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        imageUrl={imageUrl}
        setPrimaryImg={setPrimaryImg}
        type={type}
      />
    </>
  );
};

const PhotoGallery: React.FC<PhotoGalleryProps> = ({ broker, setBroker }) => {
  const { type } = useParams();
  const [isDownloading, setIsDownloading] = useState(false);
  const key = type?.toLowerCase() === "manual" ? "photos" : "ai_images";

  const downloadImagesAsZip = async () => {
    try {
      setIsDownloading(true);
      const zip = new JSZip();
      // Fetch images and add them to the zip
      const fetchImages = (broker?.[key] || [])?.map(
        async (item: AIImageProps | string, index) => {
          const response = await fetch(
            typeof item === "string" ? item : item?.image
          );
          const blob = await response.blob();
          zip.file(`image_${index + 1}.jpg`, blob);
        }
      );

      // Wait for all images to be fetched and added to the zip
      await Promise.all(fetchImages);

      // Generate the zip file
      zip.generateAsync({ type: "blob" }).then((content) => {
        // Trigger the download
        saveAs(content, "images.zip");
      });
    } catch (err) {
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="flex flex-col w-full h-full relative overflow-auto px-10">
      <div className="flex flex-row pt-8 mb-[56px] justify-between items-center">
        <div>
          <h3 className="text-xl mb-2">
            {key === "photos"
              ? "Manual photo upload gallery"
              : "AI photo gallery"}
          </h3>
          <p className="text-sm text-jll-text-base-subdued">
            Please select the photo to be used as your profile picture
          </p>
        </div>
        <SolButton
          onSol-click={downloadImagesAsZip}
          disabled={!broker?.[key]?.length || isDownloading}
        >
          <SolIcon icon="download" />
          Download All
        </SolButton>
      </div>
      {!!broker?.[key]?.length && (
        <div className="mb-[56px]">
          <p className="text-xl mb-2">
            {key !== "photos"
              ? "Aragon, AI headshot generated images"
              : "Manual upload images"}
          </p>
          <p className="text-sm text-jll-text-base-subdued mb-[56px]">
            Double click on the image to enlarge.
          </p>

          <div className="grid 2xl:grid-cols-6 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-10">
            {broker?.[key]?.map((item: AIImageProps | string, idx) => (
              <GalleryItem
                key={idx}
                item={item}
                setBroker={setBroker}
                broker={broker}
                type={key}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
