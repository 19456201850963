import { SolButton, SolIcon } from "@solstice/sol-react";
import { SET_LOADING_MODAL, USER_GROUP_PROFILE_ADMIN } from "constant";
import { Permission } from "types";
import { GlobalContext, useNavigationHistory } from "context";
import { useApiCall, useSession } from "hooks";
import Cookies from "js-cookie";
import { useContext, useState } from "react";
import {
  getSessionAPI,
  postFirstBrokerAPI,
  postFirstSessionAPI,
} from "services";
import { Radio, Toast } from "ui-atoms";
import { Modal } from "ui-molecules";
import { joinArray, parseJSON } from "utils";

interface PermissionModalProps {
  isOpen: boolean;
  setIsOpen: any;
}

const PermissionModal: React.FC<PermissionModalProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { session } = useSession();
  const isFirst = session?.permissions?.first_session;
  const { dispatch } = useContext(GlobalContext);
  const { navigate } = useNavigationHistory();
  const [getSession, , , error] = useApiCall(getSessionAPI, true);
  const [postFirstSession] = useApiCall(postFirstSessionAPI);
  const [postFirstBroker] = useApiCall(postFirstBrokerAPI);

  const [selectedOption, setSelectedOption] = useState<Permission | null>(null);

  const handleEmail = () => {
    const recipient = "profile.management@jll.com";
    const subject = "Profile Management Access Request";

    let market = "";
    if (session?.permissions?.groups?.includes("Local Admin")) {
      market = `Market(s): ${joinArray(
        session?.permissions?.markets?.map((item: any) => item?.name),
        ", "
      )}`;
    } else if (session?.permissions?.broker_id) {
      market = `Yes/No: \n\nMarket: `;
    }

    const body = `Please provide the below user with access to the Profile Management tool for the following market(s):\n\n\nUser email: ${session?.email}\n\n${market}\n\n`;

    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink);
  };

  const handleSubmit = async () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Submiting values",
        },
      });
      let API: any;
      if (selectedOption === "broker_revenue_generator") {
        API = postFirstBroker();
      } else if (selectedOption === "operations_support") {
        API = postFirstSession({
          group: USER_GROUP_PROFILE_ADMIN,
        });
      } else {
        throw new Error("Please select a role to proceed");
      }
      API?.then((res: any) => {
        getSession()
          .then((res: any) => {
            if (!res) {
              sessionStorage.removeItem("session");
              return;
            }
            if (res?.mock_user) {
              sessionStorage.setItem("mainSession", JSON.stringify(res));
              sessionStorage.setItem("session", JSON.stringify(res?.mock_user));
            } else {
              sessionStorage.setItem("session", JSON.stringify(res));
              sessionStorage.removeItem("mainSession");
            }

            if (!!Cookies.get("okta_tokens")) {
              const tokens = parseJSON(Cookies.get("okta_tokens") || "");
              if (tokens && !!Object.keys(tokens)?.length) {
                localStorage.setItem("okta_tokens", JSON.stringify(tokens));
              }
            }
          })
          .finally(() => {
            navigate("/profile");
          });
      }).finally(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: "",
          },
        });
        setIsOpen(false);
      });
    } catch (err: any) {
      Toast.error(err?.message || err);
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          title: "",
        },
      });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      size={isFirst ? "medium" : "default"}
    >
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl mt-1">Welcome to Profile Management</h2>
          </div>
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-row space-x-4 mt-4">
          {!isFirst && (
            <>
              <div className="rounded-full min-w-[56px] max-h-[56px] flex justify-center items-center bg-jll-surface-rag-danger bg-opacity-5">
                <SolIcon
                  icon="error"
                  size="32"
                  className="text-jll-icon-rag-danger"
                />
              </div>
              <div>
                <p>
                  You are not delegated to a specific market in order to access
                  the tool. Please click{" "}
                  <a
                    type="button"
                    target="_blank"
                    className="underline cursor-pointer"
                    onClick={handleEmail}
                  >
                    here
                  </a>{" "}
                  to request access
                </p>
              </div>
            </>
          )}
          {isFirst && (
            <div className="w-full">
              <p className="mb-4">
                Before getting started, tell us about how you'll be using this
                tool.
              </p>

              <div className="space-y-4 p-2">
                <Radio
                  label={
                    <>
                      <strong>Operations & Support</strong>
                      <br />
                      Manage broker profiles but do not need your own
                    </>
                  }
                  id="operations_support"
                  name="user_role"
                  required
                  checked={selectedOption === "operations_support"}
                  onChange={() => setSelectedOption("operations_support")}
                />

                <Radio
                  label={
                    <>
                      <strong>Broker & Revenue Generator</strong>
                      <br />
                      Create and manage your own profile
                    </>
                  }
                  id="broker_revenue_generator"
                  name="user_role"
                  required
                  checked={selectedOption === "broker_revenue_generator"}
                  onChange={() => setSelectedOption("broker_revenue_generator")}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 justify-end">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Close
          </SolButton>

          {isFirst && (
            <SolButton
              className="!w-[96px] sol-w-full"
              disabled={!selectedOption}
              onSol-click={handleSubmit}
            >
              Save
            </SolButton>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionModal;
