import { FC, useContext, useEffect, useRef, useState } from "react";
import cn from "classnames";
import { GlobalContext } from "context";

interface ICheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label?: string;
  error?: boolean;
  className?: string;
  labelClassName?: string;
  isMiss?: boolean;
  defaultChecked?: boolean;
  onChange?: any;
  required?: boolean;
}

const Checkbox: FC<ICheckboxProps> = ({
  label = "",
  error,
  className,
  defaultChecked = false,
  onChange,
  labelClassName = "",
  isMiss,
  required,
  ...props
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;
  const labelRef = useRef(null);
  const [isClamped, setIsClamped] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const checkTruncation = () => {
    if (labelRef.current) {
      const { scrollHeight, clientHeight } = labelRef?.current;
      setIsClamped(scrollHeight > clientHeight);
    }
  };

  // Attach the check on component mount and window resize
  useEffect(() => {
    if (!label) return;
    checkTruncation();
    window.addEventListener("resize", checkTruncation);
    return () => window.removeEventListener("resize", checkTruncation);
  }, [label]);

  const handleChange = (e: any) => {
    setIsChecked(e.target.checked);
    onChange(e);
  };

  return (
    <div className={cn("flex space-x-3 items-center", className)}>
      <div className="flex h-4 items-center relative mt-1">
        <input
          type="checkbox"
          className={cn(
            "h-4 w-4 rounded-sm text-jll-light-stroke-default bg-white focus:ring-offset-0 focus:ring-0 cursor-pointer accent-jll-stroke-default appearance-none checked:appearance-auto forced-colors:appearance-auto",
            {
              //   "border-jll-color-surface-accent-default": !!error,
              "border border-jll-light-stroke-default": !error,
            }
          )}
          checked={isChecked}
          onChange={handleChange}
          {...props}
        />
      </div>
      {label && (
        <label
          ref={labelRef}
          htmlFor={props.id}
          className={cn(
            "cursor-pointer !font-normal flex items-center !line-clamp-2",
            labelClassName,
            {
              "text-jll-color-surface-accent-default": !!error,
            }
          )}
          data-tooltip-id={props.id}
          onMouseOver={() => isClamped && showTooltip(props.id)}
          onMouseLeave={() => {
            hideTooltip();
          }}
          data-tooltip-content={label}
        >
          {label}
          {required && <span className="text-jll-stroke-danger ml-1">*</span>}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
