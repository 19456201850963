import { ArrowBackOutlined } from "@material-ui/icons";
import {
  SolAvatar,
  SolButton,
  SolIcon,
  SolStatusIndicator,
} from "@solstice/sol-react";
import {
  SolStatusIndicatorSizeEnum,
  SolStatusIndicatorStatusEnum,
} from "@solstice/sol-stencil";
import {
  PUBLISH_STATUS_PUBLISHED,
  SET_ALERT_MODAL,
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  URLS,
} from "constant";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { BrokerProps, ProfileProps } from "types";
import { getFormatedDateTime } from "utils";
import cn from "classnames";
import { LoadingPage } from "ui-molecules";
import { Badge, StatusIndicator } from "ui-atoms";
import { renderToString } from "react-dom/server";
import { patchBrokerAPI } from "services";
import { useApiCall, useSession } from "hooks";
import { useContext } from "react";
import { GlobalContext, useNavigationHistory } from "context";

interface IDetailLeftSide {
  broker?: BrokerProps | null;
  setBroker?: any;
  isLoading?: boolean;
  isLog?: boolean;
  isPhoto?: boolean;
  isInActive?: boolean;
}

const DetailLeftSide: React.FC<IDetailLeftSide> = ({
  broker,
  setBroker,
  isLoading,
  isLog,
  isPhoto,
  isInActive,
}) => {
  const { session } = useSession();
  const { navigate, history } = useNavigationHistory();
  const { dispatch, state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip } = state;
  const { brokerId } = useParams();
  const [patchBroker] = useApiCall(patchBrokerAPI);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const isFullPermission =
    !!session?.permissions?.groups?.includes("Global Admin") ||
    !!session?.is_superuser ||
    !!session?.is_staff;
  const isAvailableInActive = broker?.profiles?.some(
    (profile) => profile?.active_status !== STATUS_ACTIVE
  );

  const lastAction = [...history].reverse().find((item) => {
    return /^\/profile(?:\?.*)?$/.test(item?.toLowerCase());
  });

  const handleButtonClick = () => {
    navigate(`/profile/${brokerId}/photo`);
  };

  const handleStatus = async () => {
    try {
      const action = () => {
        patchBroker({
          pk: broker?.pk || broker?.id,
          active_status:
            broker?.active_status === STATUS_ACTIVE
              ? STATUS_INACTIVE
              : STATUS_ACTIVE,
        }).then((res: any) => {
          if (!res) return;
          setBroker(res);
        });
      };

      dispatch({
        type: SET_ALERT_MODAL,
        payload: {
          open: true,
          kind: "warn",
          title:
            broker?.active_status === STATUS_ACTIVE
              ? "Confirm set broker to inactive"
              : "Confirm set broker to active",
          description:
            broker?.active_status === STATUS_ACTIVE
              ? "This will un-publish and de-activate this broker and its profile(s)."
              : "This will enable the profiles to be published.",
          icon: "warning",
          btn3: null,
          btn1: {
            label: "Cancel",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                  btn3: null,
                },
              });
            },
          },
          btn2: {
            label: "Confirm",
            onClick: () => {
              dispatch({
                type: SET_ALERT_MODAL,
                payload: {
                  open: false,
                  kind: "",
                  title: "",
                  description: "",
                  icon: "",
                  btn1: null,
                  btn2: null,
                  btn3: null,
                },
              });
              action();
            },
          },
        },
      });
    } catch (err) {}
  };

  return (
    <div className="min-w-[280px] h-full bg-jll-surface-base-secondary relative">
      <div className="h-[68px] flex flex-row items-center pl-6 space-x-3 border-b border-b-jll-light-stroke-default">
        <button
          onClick={() =>
            isLog || isPhoto || isInActive
              ? navigate(-1)
              : navigate(lastAction || URLS.PROFILE.ROOT)
          }
          className="flex flex-row items-center"
        >
          <ArrowBackOutlined className="!w-5 !h-5 mr-2" />
          {isLog || isPhoto || isInActive ? "Back to profile" : "Back"}
        </button>
      </div>

      {isLoading ? (
        <LoadingPage mode="back" />
      ) : (
        <>
          <div
            className={cn(
              "absolute bottom-0 py-8 space-y-8 overflow-y-auto w-full top-[68px]"
            )}
          >
            <div className="border-b border-b-jll-light-stroke-default px-10 pb-8">
              <div className="flex flex-row items-center space-x-4 mb-4">
                <div
                  className="relative w-[56px] h-full cursor-pointer rounded-full hover:shadow-gallery"
                  onClick={handleButtonClick}
                >
                  <SolAvatar
                    size="56"
                    image={
                      !!broker?.photos?.length
                        ? (broker?.photos?.[0] as string)
                        : ""
                    }
                  />
                  <div className="rounded-full p-0.5 flex justify-center items-center absolute -bottom-0.5 -left-0.5 bg-jll-tonal-ocean-subdued">
                    <SolIcon
                      icon="photo_camera"
                      size="16"
                      className="text-jll-tonal-ocean-strong text-bold"
                    />
                  </div>
                </div>
                <div>
                  <p>
                    {[broker?.first_name, broker?.last_name]?.join(" ") || ""}
                  </p>
                  <p className="text-jll-text-base-subdued">
                    {broker?.profiles?.[0]?.job_title || ""}
                  </p>
                </div>
              </div>

              <Badge
                onClick={isFullPermission ? handleStatus : undefined}
                data-tooltip-id={`left_${broker?.id}_status`}
                data-tooltip-content={
                  broker?.active_status === STATUS_ACTIVE
                    ? "Remove Broker"
                    : "Activate Broker"
                }
                onMouseOver={() =>
                  isFullPermission && showTooltip(`left_${broker?.id}_status`)
                }
                onMouseLeave={() => isFullPermission && hideTooltip()}
              >
                <div className="flex flex-row items-center space-x-3">
                  <StatusIndicator
                    status={
                      broker?.active_status === STATUS_ACTIVE
                        ? "available"
                        : "away"
                    }
                    size={12}
                  />
                  <span className="text-sm">
                    {broker?.active_status === STATUS_ACTIVE
                      ? "Broker active"
                      : "Broker inactive"}
                  </span>
                </div>
              </Badge>
            </div>

            {!isLog && !isPhoto && (
              <>
                <div className="space-y-8 border-b border-b-jll-light-stroke-default px-10 pb-8">
                  {broker?.profiles
                    ?.filter(
                      (profile: ProfileProps) =>
                        profile?.active_status === STATUS_ACTIVE
                    )
                    ?.map((profile: ProfileProps, idx) => {
                      return (
                        <div className="space-y-2" key={idx}>
                          <div
                            className={cn(
                              "flex justify-between items-center cursor-pointer font-medium",
                              {
                                "text-jll-text-base-subdued-6":
                                  searchParams?.get("tab") !==
                                  profile?.id?.toString(),
                                "text-jll-text-base-default":
                                  searchParams?.get("tab") ===
                                  profile?.id?.toString(),
                              }
                            )}
                            onClick={() => {
                              if (
                                location?.pathname
                                  ?.toLowerCase()
                                  ?.includes("edit")
                              ) {
                                navigate(
                                  `/profile/${brokerId}/edit?tab=${profile?.id?.toString()}&sub=bio`
                                );
                              } else
                                navigate(
                                  `/profile/${brokerId}?tab=${profile?.id?.toString()}`
                                );
                            }}
                          >
                            <span className="text-sm">
                              {profile?.country?.name}
                            </span>
                            <SolIcon icon="keyboard_arrow_right" size="20" />
                          </div>
                          <div className="flex flex-row space-x-2 items-center">
                            <div className="w-4 h-4 rounded-full bg-white flex justify-center items-center">
                              <SolStatusIndicator
                                size={"12" as SolStatusIndicatorSizeEnum}
                                status={
                                  (profile?.publish_status ===
                                  PUBLISH_STATUS_PUBLISHED
                                    ? "available"
                                    : "busy") as SolStatusIndicatorStatusEnum
                                }
                                online
                              />
                            </div>
                            <span className="text-sm">
                              {profile?.publish_status ===
                              PUBLISH_STATUS_PUBLISHED
                                ? "Published"
                                : "Unpublished"}
                            </span>
                          </div>
                          <div className="flex flex-row space-x-1.5 items-center">
                            <SolIcon
                              icon={
                                profile?.is_compliant
                                  ? "check_circle"
                                  : "warning"
                              }
                              size="20"
                              className={cn("-ml-0.5", {
                                "text-jll-icon-rag-success-1":
                                  profile?.is_compliant,
                                "text-jll-stroke-danger":
                                  !profile?.is_compliant,
                              })}
                              data-tooltip-id={`left_preview_${profile?.id}_compliant`}
                              data-tooltip-html={renderToString(
                                <div>
                                  <p className="mb-3">
                                    {profile?.is_compliant
                                      ? "Profile compliant"
                                      : "Profile not compliant"}
                                  </p>
                                  <ul className="list-inside list-disc">
                                    {profile?.compliance_reasons?.map(
                                      (item, idx) => (
                                        <li key={idx} className="text-sm">
                                          {item}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              )}
                            />
                            <span
                              className={cn("text-sm", {
                                "text-jll-stroke-danger":
                                  !profile?.is_compliant,
                              })}
                            >
                              {profile?.is_compliant
                                ? "Profile compliant"
                                : "Profile not compliant"}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </>
            )}

            <div className="px-10">
              <div className="mb-10">
                <Badge className="mb-8">
                  <div className="flex flex-row items-center space-x-3">
                    <SolIcon icon="save" size="16" />
                    <span className="text-sm">Auto-saved</span>
                  </div>
                </Badge>
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Modified by
                </p>
                <p className="text-sm mb-1">
                  {[
                    broker?.updated_user_first_name,
                    broker?.updated_user_last_name,
                  ]?.join(" ") || broker?.updated_user_email}
                </p>
                <p className="text-jll-text-base-subdued text-sm mb-1">
                  Modified on
                </p>
                <p className="text-sm mb-1">
                  {getFormatedDateTime(broker?.update_timestamp)}
                </p>
              </div>
              <div className="flex flex-col space-y-4">
                {!isLog && !isPhoto && (
                  <SolButton
                    variant="secondary"
                    onSol-click={() =>
                      navigate(`${URLS.PROFILE.ROOT}/${broker?.id}/log`)
                    }
                  >
                    <SolIcon icon="history" />
                    View activity log
                    <SolIcon icon="arrow_right_alt" />
                  </SolButton>
                )}

                {isAvailableInActive && !isInActive && (
                  <SolButton
                    variant="secondary"
                    onSol-click={() =>
                      navigate(`${URLS.PROFILE.ROOT}/${broker?.id}/inactive`)
                    }
                  >
                    View Inactive Bios <SolIcon icon="arrow_right_alt" />
                  </SolButton>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div className="absolute bottom-0 left-0 right-0 h-[116px] py-10 px-10">
        <SolButton variant="secondary">
          <SolIcon icon="call" />
          Support
        </SolButton>
      </div> */}
    </div>
  );
};

export default DetailLeftSide;
