/**
 * Is new value null or not
 * Should be null only when the oldValue is not empty but the newValue is empty
 * @param oldValue Any type of values, object, array, string, number, ect.
 * @param newValue Any type of values, object, array, string, number, ect.
 * @returns boolean
 */
export const isNewValueNull = (oldValue: any, newValue: any) => {
  return !!oldValue && !newValue;
};

/**
 * Get value from object
 * Only support Date type object for now
 * because we dont have any other type in the payload
 * Anything else will return undefined
 * @param oldValue any value
 * @param newValue Date object: 2023-04-05T12:52:10.792422Z
 * @returns Formated date: 2023-05-03
 */
export const getObjectValue = (oldValue: any, newValue: any) => {
  if (!oldValue && !newValue) {
    return undefined;
  }
  if (typeof oldValue !== "object" && typeof newValue !== "object") {
    return undefined;
  }
  if (isNewValueNull(oldValue, newValue)) {
    return null;
  }
  // if (newValue instanceof Date && oldValue !== getApiDate(newValue)) {
  //   return getApiDate(newValue);
  // }
  return undefined;
};

/**
 * Get value form array
 * @param oldValue Any type of array. Ex: coordinators, highlights, ect.
 * @param newValue Any type of array. Ex: coordinators, highlights, ect.
 * @returns Array with string or number: [1,3,5] or ['lorem ipsum']
 */
export const getArrayValue = (oldValue: any, newValue: any, key: any) => {
  if (!oldValue && !newValue) {
    return undefined;
  }

  if (!Array.isArray(oldValue) && !Array.isArray(newValue)) {
    return undefined;
  }

  if (isNewValueNull(oldValue, newValue)) {
    return null;
  }
  // if (!!oldValue && !newValue?.length) {
  //   return null;
  // }
  let updatedOldValue = [...(oldValue || [])];
  let updatedNewValue = [...(newValue || [])];
  updatedOldValue = updatedOldValue.map((x) => {
    if (x?.amenity && !x?.custom_amenity) {
      // object with 'custom_amenity' property are custom amenities
      // ex: {custom_amenity: "something"}
      return x.amenity;
    } else if (x?.custom_amenity && !x?.amenity) {
      // object with 'custom_amenity' property are custom amenities
      // ex: {custom_amenity: "something"}
      return x.custom_amenity;
    } else if (x?.hasOwnProperty("value")) {
      // object with 'value' property are often object from multi select
      // ex: {value: 1, label: 'lorem'}
      return x.value;
    } else if (x?.hasOwnProperty("id")) {
      // object with 'id' property are often object from the server
      // ex: {id: 1, is_superuser: true, is_staff: false, get_full_name: 'Lorem'}
      return x.id;
    }
    return x;
  });

  updatedNewValue = updatedNewValue.map((x) => {
    if (x?.hasOwnProperty("value")) {
      return x.value;
    } else if (x?.amenity && !x?.custom_amenity) {
      return x.amenity;
    } else if (x?.custom_amenity && !x?.amenity) {
      return x.custom_amenity;
    } else if (x?.hasOwnProperty("id")) {
      return x.id;
    }
    return x;
  });

  if (
    JSON.stringify(updatedNewValue?.sort()) !==
    JSON.stringify(updatedOldValue?.sort())
  ) {
    // In most cases, with PATCH APIs, we only send arrays containing a list of ids
    // we never send arrays containing objects
    return updatedNewValue;
  }

  return undefined;
};

/**
 * Get new value
 * Compare the old and new value and return the new value if its different
 * Will return Null if old value is set but not the new one
 * Will return undefined is there is no change to be made
 * @param oldValue
 * @param newValue
 * @returns
 */
export const getNewValue = (oldValue: any, newValue: any, key: any) => {
  if (
    typeof newValue === "boolean" &&
    (typeof oldValue === "boolean" || oldValue === undefined) &&
    newValue !== oldValue
  ) {
    return newValue;
  }

  if (isNewValueNull(oldValue, newValue)) {
    return null;
  }
  const objectValue = getObjectValue(oldValue, newValue);
  if (objectValue) {
    return objectValue;
  }
  const arrayValue = getArrayValue(oldValue, newValue, key);
  if (arrayValue) {
    return arrayValue;
  }
  if (typeof newValue === "object") {
    return undefined;
  }
  if (!oldValue && !newValue) {
    return undefined;
  }
  if (oldValue?.toString() !== newValue?.toString()) {
    return newValue;
  }
  return undefined;
};

/**
 * Get api payload from form
 * Only the values that have change will output in the result
 * This is because we dont want to send unchanged value in PATCH api
 * @param oldValues Object of old values: property or availability
 * @param newValues Object of new values: form values
 * @returns Object of values
 */
export const getApiPayloadFromForm = (oldValues: any, newValues: any) => {
  let payload = {};

  Object.keys(newValues).forEach((key) => {
    const oldValue = oldValues[key];
    const newValue = newValues[key];

    const value = getNewValue(oldValue, newValue, key);

    if (
      value === null ||
      !!value ||
      (typeof value === "boolean" && oldValue != newValue)
    ) {
      payload = {
        ...payload,
        [key]: value,
      };
    }
  });

  if (!Object.keys(payload)?.length) {
    return undefined;
  }

  return payload;
};
