import { SolButton, SolIcon } from "@solstice/sol-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TopBar } from "ui-molecules";
import { LayoutBase } from "ui-organisms";
import Img404 from "assets/images/404.svg";
import { useNavigationHistory } from "context";

const NoPage = () => {
  const { navigate } = useNavigationHistory();
  
  return (
    <LayoutBase>
      <TopBar />
      <div className="absolute top-[80px] bottom-0 left-0 right-0 flex flex-col">
        <div className="h-3/4 bg-jll-surface-base-default flex justify-center items-center relative">
          <LazyLoadImage src={Img404} className="h-full" />
          <div className="absolute top-[15%] right-0 left-0 bottom-0 flex justify-center items-center flex-col">
            <p className="text-white text-3xl mb-4">404</p>
            <p className="text-jll-text-base-subdued-3 text-2xl mb-12">
              Page not found
            </p>
            <p className="text-white text-sm">
              We’re sorry, the page you were looking for may{" "}
            </p>
            <p className="text-white text-sm">
              have moved or no longer available!
            </p>
          </div>
        </div>
        <div className="h-1/4 flex bg-white justify-center items-center space-x-4">
          <SolButton
            variant="secondary"
            size="small"
            onSol-click={() => navigate("/")}
          >
            <SolIcon icon="home" />
            Back to the home page
          </SolButton>
          <SolButton variant="secondary" size="small">
            <SolIcon icon="call" />
            Contact us
          </SolButton>
        </div>
      </div>
    </LayoutBase>
  );
};

export default NoPage;
