import { SolButton, SolIcon } from "@solstice/sol-react";
import { SET_LOADING_MODAL } from "constant";
import { GlobalContext, useNavigationHistory } from "context";
import { useApiCall } from "hooks";
import { useContext } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate, useParams } from "react-router-dom";
import { patchBrokerAPI } from "services";
import { BrokerProps, PhotoHistoryProps } from "types";
import { Toast } from "ui-atoms";
import { Table } from "ui-molecules";
import { getFormatedDateTime } from "utils";

interface ProfilePhotoProps {
  broker: BrokerProps | null;
  setBroker: any;
}

const ProfilePhoto: React.FC<ProfilePhotoProps> = ({ broker, setBroker }) => {
  const { dispatch } = useContext(GlobalContext);
  const { brokerId } = useParams();
  const { navigate } = useNavigationHistory();
  const [patchBroker] = useApiCall(patchBrokerAPI);

  const handleAragon = () => {
    navigate(`/profile/${brokerId}/photo/tutorial`);
  };

  const setPrimaryImg = async (url: string) => {
    dispatch({
      type: SET_LOADING_MODAL,
      payload: {
        open: true,
        title: "Setting a primary photo",
      },
    });
    const newArr =
      [...(broker?.photos || [])]?.filter((item) => item !== url) || [];

    patchBroker({
      pk: broker?.pk || broker?.id,
      photos: [url, ...newArr],
    })
      .then((res: any) => {
        if (!res) return;
        setBroker(res);
        Toast.success("Profile photo successfully updated!");
      })
      .finally(() => {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: "",
          },
        });
      });
  };

  return (
    <>
      <div className="flex flex-col w-full h-full relative overflow-auto px-10">
        <div className="flex flex-row justify-between items-center pt-8 mb-14">
          <div>
            <h3 className="text-xl mb-1">Profile image process</h3>
            <p className="text-sm text-jll-text-base-subdued">
              Choose your preferred method for a professional headshot
            </p>
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 relative gap-12">
          <div className="col-span-1 bg-jll-surface-base-secondary-subdued rounded-xl flex justify-center items-center flex-col p-6 h-[330px]">
            <h3 className="text-xl mb-2">AI Headshot Generator</h3>
            <p className="text-jll-text-base-subdued text-sm text-center mb-6">
              This innovative tool generates high-quality, personalized
              headshots tailored to your preferences. Click to be redirected to
              Aragon's external site to complete the process.
            </p>
            <div className="flex flex-row items-center space-x-4">
              <SolButton
                className="whitespace-nowrap"
                variant="secondary"
                onSol-click={() =>
                  navigate(`/profile/${brokerId}/photo/gallery/ai`)
                }
                disabled={!broker?.ai_images?.length}
              >
                <SolIcon icon="gallery_thumbnail" /> AI photo gallery
              </SolButton>
              <SolButton onSol-click={handleAragon}>
                Start <SolIcon icon="arrow_right_alt" />
              </SolButton>
            </div>
          </div>
          <div className="col-span-1 bg-jll-surface-base-secondary-subdued rounded-xl flex justify-center items-center flex-col p-6 h-[330px]">
            <h3 className="text-xl mb-2">Upload Your Own Photo</h3>
            <p className="text-jll-text-base-subdued text-sm text-center mb-6">
              You can upload your own photo that follows our best practice
              guidelines for headshots. This option allows you to use an
              existing image that meets our professional standards.
            </p>
            <div className="flex flex-row items-center space-x-4">
              <SolButton
                className="whitespace-nowrap"
                variant="secondary"
                onSol-click={() =>
                  navigate(`/profile/${brokerId}/photo/gallery/manual`)
                }
                disabled={!broker?.photos?.length}
              >
                <SolIcon icon="gallery_thumbnail" /> Manual upload photo gallery
              </SolButton>
              <SolButton
                onSol-click={() =>
                  navigate(`/profile/${brokerId}/photo/manual`)
                }
              >
                Start <SolIcon icon="arrow_right_alt" />
              </SolButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePhoto;
