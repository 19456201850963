import cn from "classnames";

interface BadgeProps {
  children: any;
  kind?: "default";
  className?: string;
  onClick?: any;
  onMouseOver?: any;
  onMouseLeave?: any;
}

const Badge: React.FC<BadgeProps> = ({
  children,
  kind,
  className,
  onClick,
  ...props
}) => {
  let backgoundColor;
  switch (kind) {
    default:
      backgoundColor = "bg-jll-surface-base-secondary-alpha-1 bg-opacity-10";
  }

  return (
    <button
      className={cn("rounded px-3 py-1.5 w-fit", backgoundColor, className, {
        "cursor-pointer": onClick,
        "cursor-default": !onClick,
      })}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Badge;
