import { SolButton, SolIcon } from "@solstice/sol-react";
import { Modal } from "ui-molecules";
import Cropper from "./Cropper";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import cn from "classnames";
import * as fabric from "fabric";
import CropControl from "./CropControl";
import AdjustControl from "./AdjustControl";
import { patchBrokerAPI, postImageAPI } from "services";
import { useApiCall } from "hooks";
import { BrokerProps } from "types";
import { GlobalContext } from "context";
import { SET_LOADING_MODAL } from "constant";
import { useNavigate, useParams } from "react-router-dom";

const TABS = [
  { label: "Crop", value: "crop" },
  { label: "Adjust", value: "adjust" },
];

interface EditPhotoModalProps {
  isOpen: boolean;
  setIsOpen: any;
  image?: string;
  broker: BrokerProps | null;
  setBroker: any;
}

const EditPhotoModal: React.FC<EditPhotoModalProps> = ({
  isOpen,
  setIsOpen,
  image,
  setBroker,
  broker,
}) => {
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const { dispatch } = useContext(GlobalContext);
  const [patchBroker] = useApiCall(patchBrokerAPI);
  const previewRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);
  const fabricCanvasRef = useRef<any>(null);
  const cropperRef = useRef<any>(null);

  const [croppedArea, setCroppedArea] = useState(null);
  const [activeTab, setActiveTab] = useState("crop");

  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [aspect, setAspect] = useState(4 / 5);
  const [flipX, setFlipX] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [vignette, setVignette] = useState(0);

  useEffect(() => {
    if (!isOpen || !image || !canvasRef.current) return;
    // initValues();
    const canvas = new fabric.Canvas(canvasRef.current, {
      width: (previewRef?.current?.offsetWidth * 5) / 5,
      height: (previewRef?.current?.offsetHeight * 5) / 5,
    });
    fabricCanvasRef.current = canvas;

    canvas.clear();

    fabric.FabricImage.fromURL(image!, { crossOrigin: "anonymous" }).then(
      (img) => {
        const canvasWidth = (previewRef?.current?.offsetWidth * 5) / 5;
        const canvasHeight = (previewRef?.current?.offsetHeight * 5) / 5;
        // Get the aspect ratio of the image
        const imageAspectRatio = img.width / img.height;

        // Get the aspect ratio of the canvas
        const canvasAspectRatio = canvasWidth / canvasHeight;

        let scaleFactor;
        // Determine the scaling factor based on the aspect ratios
        if (imageAspectRatio > canvasAspectRatio) {
          // Image is wider than canvas
          scaleFactor = canvasWidth / img.width;
        } else {
          // Image is taller than canvas
          scaleFactor = canvasHeight / img.height;
        }
        // Apply scaling to maintain aspect ratio
        img.scale(scaleFactor);

        // Center the image on the canvas
        img.set({
          left: (canvasWidth - img.width * scaleFactor) / 2,
          top: (canvasHeight - img.height * scaleFactor) / 2,
          originX: "left",
          originY: "top",
          imageSmoothingEnabled: false,
        });

        img.setCoords();

        canvas.add(img);
        canvas.renderAll();
        cropperRef.current?.onMediaLoad(img.width, img.height);
      }
    );

    return () => {
      if (fabricCanvasRef?.current) {
        fabricCanvasRef.current.dispose();
        fabricCanvasRef.current = null;
        initValues();
      }
    };
  }, [isOpen, image, canvasRef]);

  useEffect(() => {
    if (!fabricCanvasRef.current) return;
    const imgInstance = fabricCanvasRef.current?.item(0);
    if (!imgInstance) return;
    let filters = imgInstance?.filters || [];
    // Check if there's already a brightness filter, if not, create one
    let brightnessFilter = filters.find(
      (filter: any) => filter?.type === "Brightness"
    );
    if (!brightnessFilter) {
      // If no brightness filter exists, create a new one
      brightnessFilter = new fabric.filters.Brightness({
        brightness, // brightnessValue is the input for real-time change
      });
      filters.push(brightnessFilter); // Add to the filter array
    } else {
      // If brightness filter exists, just update the brightness value
      brightnessFilter.brightness = brightness;
    }
    imgInstance["filters"] = filters; // Set the modified filters
    imgInstance.applyFilters(); // Apply filters to the image
    fabricCanvasRef.current?.renderAll();
  }, [brightness]);

  useEffect(() => {
    if (!fabricCanvasRef.current) return;
    const imgInstance = fabricCanvasRef.current.item(0);
    if (!imgInstance) return;
    let filters = imgInstance?.filters || [];
    let contrastFilter = filters.find(
      (filter: any) => filter?.type === "Contrast"
    );
    if (!contrastFilter) {
      contrastFilter = new fabric.filters.Contrast({
        contrast,
      });
      filters.push(contrastFilter);
    } else {
      contrastFilter.contrast = contrast;
    }
    imgInstance["filters"] = filters;
    imgInstance.applyFilters();
    fabricCanvasRef.current.renderAll();
  }, [contrast]);

  useEffect(() => {
    if (!fabricCanvasRef.current) return;
    const imgInstance = fabricCanvasRef.current.item(0);
    if (!imgInstance) return;
    let filters = imgInstance?.filters || [];
    let saturationFilter = filters.find(
      (filter: any) => filter?.type === "Saturation"
    );
    if (!saturationFilter) {
      saturationFilter = new fabric.filters.Saturation({
        saturation,
      });
      filters.push(saturationFilter);
    } else {
      saturationFilter.saturation = saturation;
    }
    imgInstance["filters"] = filters;
    imgInstance.applyFilters();
    fabricCanvasRef.current.renderAll();
  }, [saturation]);

  const initValues = () => {
    setCroppedArea(null);
    setActiveTab("crop");
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setRotation(0);
    setAspect(4 / 5);
    setFlipX(false);
    setBrightness(0);
    setContrast(0);
    setSaturation(0);
    setVignette(0);
  };

  const onCropComplete = useCallback((croppedArea: any, croppedAreaP: any) => {
    setCroppedArea(croppedArea);
  }, []);

  const handleCroppedImage = async () => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Saving a photo",
        },
      });
      const newCanvasEl = document.createElement("canvas");
      newCanvasEl.width = fabricCanvasRef.current.width;
      newCanvasEl.height = fabricCanvasRef.current.height;

      const newCanvas = new fabric.Canvas(newCanvasEl);
      const objects = fabricCanvasRef.current.toObject();

      // Import objects from the original canvas into the cloned canvas
      await newCanvas.loadFromJSON(
        objects,
        newCanvas.renderAll.bind(newCanvas)
      );
      const image = newCanvas.item(0);
      image.scale(zoom);
      image.set({ angle: rotation });
      image.set({ flipX: flipX });

      const dataUrl = image.toDataURL();
      const croppedImg = await getCroppedImg(dataUrl, croppedArea);

      const result = await postImageAPI(croppedImg);
      if (!result) return;
      patchBroker({
        pk: broker?.pk || broker?.id,
        photos: [result, ...(broker?.photos || [])],
      })
        .then((res: BrokerProps) => {
          if (!res) return;
          setBroker(res);
        })
        .finally(() => {
          setIsOpen(false);
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
          navigate(`/profile/${brokerId}/photo/gallery/manual`);
        });
    } catch (e) {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          title: "",
        },
      });
    }
  };

  const getCroppedImg = (imageSrc: string, croppedAreaPercentage: any) => {
    const image = new Image();
    image.src = imageSrc;
    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");

        // Get the crop dimensions from the percentage
        const { x, y, width, height } = croppedAreaPercentage;
        const imgWidth = image.width;
        const imgHeight = image.height;

        // Calculate the crop area in pixels
        const cropX = (x / 100) * imgWidth;
        const cropY = (y / 100) * imgHeight;
        const cropWidth = (width / 100) * imgWidth;
        const cropHeight = (height / 100) * imgHeight;

        // Set Aspect
        const currentRatio = cropWidth / cropHeight;

        let targetWidth = cropWidth;
        let targetHeight = cropHeight;

        if (currentRatio > aspect) {
          // Width is too large; adjust to maintain aspect ratio
          targetWidth = cropHeight * aspect;
        } else if (currentRatio < aspect) {
          // Height is too large; adjust to maintain aspect ratio
          targetHeight = cropWidth / aspect;
        }

        // Set the canvas size to the cropped image size
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        ctx.save();

        // Draw the cropped part of the image to the canvas
        ctx.drawImage(
          image,
          cropX,
          cropY,
          targetWidth,
          targetHeight,
          0,
          0,
          targetWidth,
          targetHeight
        );

        // Get the data URL of the cropped image
        const croppedImageUrl = canvas.toDataURL("image/jpeg");
        resolve(croppedImageUrl);
      };
    });
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="extra">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl">Edit photo</h2>
            <p>Set your headshot's size, aspect ratio, and style.</p>
          </div>
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body className="!overflow-hidden">
        <div className="grid grid-cols-2 gap-20 pt-6 h-[70vh] relative">
          <div className="col-span-1 relative h-full" ref={previewRef}>
            <Cropper
              image={image}
              imageRef={canvasRef}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              aspect={aspect}
              classes={{
                containerClassName: "rounded-lg",
                cropAreaClassName: "w-full h-full",
              }}
              style={{
                containerStyle: {
                  transform: `scale(${flipX ? -1 : 1}, 1)`,
                },
              }}
              ref={cropperRef}
            />
          </div>
          <div className="col-span-1 relative">
            {/* Tab */}
            <div className="flex flex-row space-x-6 border-b border-jll-stroke-default-1">
              {TABS.map(({ label, value }, idx) => (
                <div
                  key={idx}
                  className={cn("py-6 -mb-px border-b cursor-pointer", {
                    "text-jll-text-base-default border-jll-text-base-default":
                      activeTab === value,
                    "text-jll-text-base-subdued border-jll-stroke-default-1":
                      activeTab !== value,
                  })}
                  onClick={() => {
                    setActiveTab(value);
                  }}
                >
                  {label}
                </div>
              ))}
            </div>

            <div className="absolute top-[75px] bottom-0 left-0 right-0 overflow-y-auto pt-6">
              {activeTab === "crop" && (
                <CropControl
                  image={image}
                  setZoom={setZoom}
                  zoom={zoom}
                  setAspect={setAspect}
                  aspect={aspect}
                  rotation={rotation}
                  setRotation={setRotation}
                  flipX={flipX}
                  setFlipX={setFlipX}
                />
              )}

              {activeTab === "adjust" && (
                <AdjustControl
                  setBrightness={setBrightness}
                  brightness={brightness}
                  setContrast={setContrast}
                  contrast={contrast}
                  setSaturation={setSaturation}
                  saturation={saturation}
                  vignette={vignette}
                  setVignette={setVignette}
                />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 w-full justify-end">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Cancel
          </SolButton>
          <SolButton
            className="!w-[96px] sol-w-full"
            onSol-click={async () => {
              handleCroppedImage();
            }}
          >
            Save
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPhotoModal;
