import type { FC, ReactNode } from "react";
import cn from "classnames";

interface IRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: ReactNode;
  error?: boolean;
  className?: string;
}

const Radio: FC<IRadioProps> = ({ label = "", error, className, ...props }) => {
  return (
    <div className={cn("flex items-center space-x-3", className)}>
      <div className="flex h-5 items-center">
        <input
          type="radio"
          className={cn(
            "h-4 w-4 border-jll-light-stroke-default text-jll-stroke-interaction focus:ring-jll-stroke-interaction",
            {
              "border-jll-color-surface-accent-default text-jll-color-surface-accent-default focus:ring-jll-color-surface-accent-default":
                !!error,
            }
          )}
          {...props}
        />
      </div>
      <div className="ml-3">
        {label && (
          <label
            htmlFor={props.id}
            className={cn("cursor-pointer", {
              // "text-jll-color-surface-accent-default": !!error,
            })}
          >
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export default Radio;
