import { Slider } from "ui-atoms";

interface AdjustControlProps {
  setBrightness: any;
  brightness: number;
  setContrast: any;
  contrast: number;
  setSaturation: any;
  saturation: number;
  setVignette: any;
  vignette: number;
}
const AdjustControl: React.FC<AdjustControlProps> = ({
  brightness,
  setBrightness,
  contrast,
  setContrast,
  saturation,
  setSaturation,
}) => {
  return (
    <div>
      <div className="mb-6">
        <p className="text-sm text-jll-text-base-subdued mb-4">Brightness</p>
        <div className="w-full px-2">
          <Slider
            min={-1}
            step={0.01}
            max={1}
            value={brightness}
            onChange={(val: number) => setBrightness(val)}
          />
        </div>
      </div>

      <div className="mb-6">
        <p className="text-sm text-jll-text-base-subdued mb-4">Contrast</p>
        <div className="w-full px-2">
          <Slider
            min={-1}
            step={0.01}
            max={1}
            value={contrast}
            onChange={(val: number) => setContrast(val)}
          />
        </div>
      </div>

      <div className="mb-6">
        <p className="text-sm text-jll-text-base-subdued mb-4">Saturation</p>
        <div className="w-full px-2">
          <Slider
            min={-1}
            step={0.01}
            max={1}
            value={saturation}
            onChange={(val: number) => setSaturation(val)}
          />
        </div>
      </div>

      {/* <div className="mb-6">
        <p className="text-sm text-jll-text-base-subdued mb-4">Vignette</p>
        <div className="w-full px-2">
          <Slider
            min={-1}
            step={0.01}
            max={1}
            value={vignette}
            onChange={(val: number) => setVignette(val)}
          />
        </div>
      </div> */}
    </div>
  );
};

export default AdjustControl;
