import AragonStep1Img from "assets/images/aragon_step1.jpeg";
import AragonStep2Img from "assets/images/aragon_step2.jpeg";
import AragonStep3Img from "assets/images/aragon_step3.jpeg";
import AragonStep4Img from "assets/images/aragon_step4.jpeg";
import { SolButton, SolIcon } from "@solstice/sol-react";
import { useApiCall } from "hooks";
import { postProfileAragonAPI } from "services";
import { useParams } from "react-router-dom";
import { BrokerProps } from "types";
import { TutorialStep } from "ui-molecules";

const STEPS = [
  {
    index: 1,
    title: "Sign up and answer questions",
    description: "Create your account and tell us a bit about yourself.",
    imageUrl: AragonStep1Img,
  },
  {
    index: 2,
    title: "Upload your photos",
    description:
      "Choose and upload six of your best photos, replace any that don’t quite fit the bill.",
    imageUrl: AragonStep2Img,
  },
  {
    index: 3,
    title: "Confirm and wait",
    description:
      "Double-check your details, then sit back while Aragon generates your headshots.",
    imageUrl: AragonStep3Img,
  },
  {
    index: 4,
    title: "Review and select",
    description: "Browse your new AI headshots and pick your favorite.",
    imageUrl: AragonStep4Img,
  },
];

interface AragonTutotialProps {
  broker: BrokerProps | null;
}

const AragonTutotial: React.FC<AragonTutotialProps> = ({ broker }) => {
  const { brokerId } = useParams();
  const [postProfileAragon] = useApiCall(postProfileAragonAPI);

  const handleAragon = () => {
    try {
      postProfileAragon({ broker: brokerId })?.then((res: any) => {
        if (res?.url) window.open(res?.url, "_blank");
      });
    } catch (err) {}
  };

  return (
    <div className="flex flex-col w-full h-full relative overflow-auto px-10">
      <div className="flex flex-col pt-8 mb-12">
        <h3 className="text-xl mb-2">AI Headshots by Aragon</h3>
        <p className="text-sm text-jll-text-base-subdued">
          Welcome to your AI headshot experience! Here’s what you can expect
        </p>
      </div>

      <TutorialStep steps={STEPS} className="mb-10" />

      <p className="text-sm text-jll-stroke-danger mb-8">
        To ensure proper authentication, you must access Aragon through the
        Profile Management tool. Visiting the Aragon website directly will not
        grant you the necessary permissions.
        <br />
        <br />
        For secure and authorized access, please click the button below to
        proceed.
      </p>
      <div className="mb-20">
        <SolButton onSol-click={handleAragon}>
          Click to proceed <SolIcon icon="open_in_new" />
        </SolButton>
      </div>
    </div>
  );
};

export default AragonTutotial;
