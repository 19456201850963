import { SolAvatar } from "@solstice/sol-react";
import { GlobalContext } from "context";
import { useContext } from "react";
import { BrokerProps, UserProps } from "types";
import { renderToString } from "react-dom/server";
import { Table } from "ui-molecules";
import { getFormatedDate, getFormatedDateTime } from "utils";

export interface StyledListValueProps {
  broker: BrokerProps | UserProps;
  valueKey: keyof BrokerProps | keyof UserProps | "name" | "country_label";
}

const StyledListValue: React.FC<StyledListValueProps> = ({
  broker,
  valueKey,
}) => {
  const { state } = useContext(GlobalContext);
  const { showTooltip, hideTooltip, meta } = state;

  return (
    <>
      {(() => {
        switch (valueKey) {
          case "name":
          case "displayName":
            return (
              <div className="flex flex-row items-center space-x-3">
                <SolAvatar
                  image={(broker as BrokerProps)?.photos?.[0] || ""}
                  size="36"
                />
                <div>
                  <p>
                    {[
                      (broker as BrokerProps)?.first_name ||
                        (broker as any)?.firstName,
                      (broker as BrokerProps)?.last_name ||
                        (broker as any)?.lastName,
                    ].join(" ") ||
                      (broker as UserProps)?.displayName ||
                      broker?.email ||
                      ""}
                  </p>
                </div>
              </div>
            );

          case "language_id":
            const lang = meta?.language?.find(
              (item: any) =>
                item?.id?.toString() ===
                (broker as BrokerProps)?.language_id?.toString()
            );
            return lang?.name || "-";

          case "country":
          case "market":
            if (!(broker as BrokerProps)?.[valueKey]?.length) return "-";
            return (
              <div
                data-tooltip-id={`${
                  (broker as BrokerProps)?.pk
                }_${valueKey}_show_more`}
                onMouseOver={() =>
                  (broker as BrokerProps)?.[valueKey]?.length > 2 &&
                  showTooltip(
                    `${(broker as BrokerProps)?.pk}_${valueKey}_show_more`
                  )
                }
                onMouseLeave={() => {
                  hideTooltip();
                }}
                data-tooltip-html={renderToString(
                  <ul>
                    {((broker as BrokerProps)?.[valueKey] || [])?.map(
                      (item: string, idx) => (
                        <li key={idx}>{item}</li>
                      )
                    )}
                  </ul>
                )}
              >
                {((broker as BrokerProps)?.[valueKey] || [])
                  .slice(0, 2)
                  ?.map((item: string, idx) => (
                    <p key={idx}>{item}</p>
                  ))}
                {(broker as BrokerProps)?.[valueKey]?.length > 2 && <p>...</p>}
              </div>
            );

          case "country_label":
            return <>{broker?.country || "-"}</>;

          case "job_title":
            return (
              <Table.Td.Clamp
                id={`profile_${
                  (broker as BrokerProps)?.pk | (broker as UserProps)?.userId
                }`}
              >
                {(broker as BrokerProps)?.[valueKey]}
              </Table.Td.Clamp>
            );

          case "leadership":
            if (!(broker as BrokerProps)?.leadership) return <>-</>;
            return (
              <>
                {
                  meta?.broker?.leadership?.[
                    (broker as BrokerProps)?.leadership!
                  ]?.value
                }
              </>
            );

          case "update_timestamp":
            return getFormatedDateTime((broker as BrokerProps)?.[valueKey], {
              dateStyle: "medium",
              timeStyle: "short",
            });

          default:
            if (broker?.hasOwnProperty(valueKey)) {
              return <>{(broker as any)?.[valueKey] || ""}</>;
            }
            return <>-</>;
        }
      })()}
    </>
  );
};

export default StyledListValue;
