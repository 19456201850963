import { Helmet } from "react-helmet-async";
import RootRoutes from "routes";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";
import "react-toastify/dist/ReactToastify.css";
import "react-tooltip/dist/react-tooltip.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalContext, useNavigationHistory } from "context";
import { SET_TOOLTIP } from "constant";
import { parseJSON } from "utils";
import { Security } from "@okta/okta-react";
import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import oktaAuth from "oktaConfig";

const App = () => {
  const { dispatch } = useContext(GlobalContext);
  const [anchorId, setAnchorId] = useState("");
  const { navigate } = useNavigationHistory();
  const isLocal = parseJSON(
    process.env?.REACT_APP_AUTH_API_PREFIX_URL || ""
  )?.includes("local")
    ? true
    : false;

  const showTooltip = useCallback(
    (id: string) => {
      setAnchorId(id);
    },
    [anchorId]
  );

  const hideTooltip = useCallback(() => {
    setAnchorId("");
  }, [anchorId]);

  useEffect(() => {
    if (!dispatch) return;
    dispatch({
      type: SET_TOOLTIP,
      payload: { showTooltip, hideTooltip },
    });
  }, [dispatch]);

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Helmet prioritizeSeoTags>
          {!isLocal && (
            <meta
              http-equiv="Content-Security-Policy"
              content="upgrade-insecure-requests"
            />
          )}
        </Helmet>
        <RootRoutes />
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          className="!w-[400px]"
          bodyClassName="!px-[10px] !py-3"
        />
        <Tooltip
          id={anchorId}
          place="top-start"
          variant="light"
          isOpen={!!anchorId}
        />
      </Security>
    </>
  );
};

export default App;
