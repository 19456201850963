import { SolButton, SolIcon } from "@solstice/sol-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Modal } from "ui-molecules";

interface ViewPhotoModalProps {
  isOpen: boolean;
  setIsOpen: any;
  imageUrl: string;
  setPrimaryImg: any;
  type: "photos" | "ai_images";
}
const ViewPhotoModal: React.FC<ViewPhotoModalProps> = ({
  isOpen,
  setIsOpen,
  imageUrl,
  setPrimaryImg,
  type,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} size="medium">
      <Modal.Header>
        <div className="w-full flex flex-row justify-between">
          <div className="flex flex-col">
            <h2 className="text-xl">
              {type !== "photos"
                ? "Aragon, AI headshot generated image"
                : "Manual upload image"}
            </h2>
            <p>
              Click on “Save” button to use this image as your profile picture.
            </p>
          </div>
          <SolButton variant="tertiary" onSol-click={() => setIsOpen(false)}>
            <SolIcon icon="close" />
          </SolButton>
        </div>
      </Modal.Header>
      <Modal.Body className="mt-6">
        <div className="w-full h-full overflow-hidden flex justify-center items-center">
          <LazyLoadImage src={imageUrl} className="rounded-lg w-auto h-full" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex flex-row space-x-3 w-full justify-end">
          <SolButton
            variant="secondary"
            className="!w-[96px] sol-w-full"
            onSol-click={() => setIsOpen(false)}
          >
            Cancel
          </SolButton>
          <SolButton
            className="!w-[96px] sol-w-full"
            onSol-click={async () => {
              await setPrimaryImg(imageUrl);
              setIsOpen(false);
            }}
          >
            Save
          </SolButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewPhotoModal;
