import { SolButton, SolIcon } from "@solstice/sol-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BrokerProps, ProfileProps } from "types";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import MainBio from "./MainBio";
import SectionBio from "./SectionBio";
import { useSearchParams } from "react-router-dom";
import { LoadingPage, TutorialStep } from "ui-molecules";
import { useApiCall } from "hooks";
import { patchProfileAPI, postProfileGPTAPI } from "services";
import cn from "classnames";
import PromptBio from "./PromptBio";
import { Toast } from "ui-atoms";
import { parseOriginalJSON } from "utils";
import { SET_LOADING_MODAL } from "constant";
import { GlobalContext } from "context";
import ManualBio from "./ManualBio";
import BioImg1 from "assets/images/bio_1.png";
import BioImg2 from "assets/images/bio_2.png";
import BioImg3 from "assets/images/bio_3.png";
import BioImg4 from "assets/images/bio_4.png";

interface BioProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
  broker?: BrokerProps | null;
  reloadBroker: () => void;
}

const STEP_FLOW = [
  {
    index: 1,
    title: "Add data",
    description: "Generate an AI written Bio using the data.",
    imageUrl: BioImg1,
  },
  {
    index: 2,
    title: "Prompts data",
    description:
      "Please use prompts to generate an AI written Bio using the fields in Step 1 (Add data).",
    imageUrl: BioImg2,
  },
  {
    index: 3,
    title: "Generate the bio",
    description: "Click on Generate bio using JLL GPT",
    imageUrl: BioImg3,
  },
  {
    index: 4,
    title: "Review and save the bio",
    description: "Please use the Save button to save the bio.",
    imageUrl: BioImg4,
  },
];

const STEPS = [
  { label: "Add data", value: "section" },
  { label: "Prompts data", value: "prompt" },
  { label: "Review and save the bio", value: "main" },
];

const Bio = forwardRef(
  ({ profile, setProfile, isLoading, broker, reloadBroker }: BioProps, ref) => {
    const { dispatch } = useContext(GlobalContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const promptRef = useRef<any>();
    const mainRef = useRef<any>();
    const [mode, setMode] = useState<any>("general"); // 1: GPT Mode - gpt, 2: Manual Mode - manual
    const [activeTab, setActiveTab] = useState<any>("tutorial"); // 0 - tutorial, 1 - section, 2 - prompt, 3 - main
    const [patchProfile] = useApiCall(patchProfileAPI);
    const [postProfileGPT] = useApiCall(postProfileGPTAPI, true, true);
    // const [prompt, setPrompt] = useState("");

    const isDisabledGenerate =
      !profile?.experience &&
      !profile?.expertise &&
      !profile?.education &&
      // !profile?.affiliations &&
      !profile?.recognition &&
      !profile?.certified_appraiser &&
      !profile?.clients;

    useEffect(() => {
      if (searchParams?.get("mode")?.toLowerCase() !== mode) {
        setMode(searchParams?.get("mode")?.toLowerCase() || "general");
      }
      if (searchParams?.get("active")?.toLowerCase() !== activeTab) {
        setActiveTab(searchParams?.get("active")?.toLowerCase() || "tutorial");
      }
    }, [searchParams]);

    const handleGPT = async () => {
      try {
        const prompt = promptRef?.current?.getPromptData();
        if (!prompt?.length) {
          Toast.warn("Please fill out the Build Prompt first.");
          // setActiveTab();
          searchParams.set("active", "prompt");
          setSearchParams(searchParams);
          return;
        }

        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            title: "Generating bio with JLL GPT",
          },
        });
        const res = await postProfileGPT({
          broker_profile: searchParams?.get("tab"),
          prompt,
        });
        if (!res?.response?.length) {
          patchProfile({
            pk: profile?.id,
            bio: "",
          })
            .then((res1: any) => {
              if (!res1) return;
              setProfile(res1);
              reloadBroker();
            })
            ?.finally(() => {
              dispatch({
                type: SET_LOADING_MODAL,
                payload: {
                  open: false,
                  title: "",
                },
              });
              // setActiveTab(3);
              searchParams.set("active", "main");
              setSearchParams(searchParams);
            });
          return;
        }
        patchProfile({
          pk: profile?.id,
          bio: res?.response,
        })
          .then((res1: any) => {
            if (!res1) return;
            setProfile(res1);
            reloadBroker();
          })
          ?.finally(() => {
            dispatch({
              type: SET_LOADING_MODAL,
              payload: {
                open: false,
                title: "",
              },
            });
            // setActiveTab(3);
            searchParams.set("active", "main");
            setSearchParams(searchParams);
          });
      } catch (err) {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: false,
            title: "",
          },
        });
      }
    };

    return (
      <>
        {isLoading ? (
          <LoadingPage />
        ) : (
          <div className="w-full overflow-y-auto flex flex-col relative h-full px-10 pt-8">
            {mode === "general" && (
              <>
                <div className="mb-6">
                  <h2 className="text-xl mb-1">Bio</h2>
                  <p className="text-sm text-jll-text-base-subdued">
                    Use JLL GPT to update your bio or you can update your own
                    bio yourself!
                  </p>
                </div>

                <div className="flex flex-row items-center space-x-4 mb-8">
                  <SolButton
                    variant="secondary"
                    onSol-click={() => {
                      searchParams.set("mode", "gpt");
                      setSearchParams(searchParams);
                    }}
                  >
                    Update bio using JLL GPT
                  </SolButton>
                  <SolButton
                    variant="secondary"
                    onSol-click={() => {
                      searchParams.set("mode", "manual");
                      setSearchParams(searchParams);
                    }}
                  >
                    Manual update
                  </SolButton>
                </div>

                <div
                  className="bio-preview mb-12"
                  dangerouslySetInnerHTML={{
                    __html: parseOriginalJSON(profile?.bio),
                  }}
                />
              </>
            )}

            {mode === "gpt" && (
              <>
                <div
                  className={cn({
                    "mb-12": activeTab === "tutorial",
                    "mb-6": activeTab !== "tutorial",
                  })}
                >
                  <h2 className="text-xl mb-1">Bio - Update using JLL GPT!</h2>
                  <p className="text-sm text-jll-text-base-subdued">
                    Please follow the steps to update your bio using JLL GPT.
                    The information entered into this form will be used to
                    prompt bio generate and will not display in bio.
                  </p>
                </div>
                {activeTab === "tutorial" && (
                  <>
                    <TutorialStep steps={STEP_FLOW} className="mb-12" />
                    <div className="flex flex-row items-center space-x-4 mb-12">
                      <SolButton
                        variant="outlined"
                        onSol-click={() => {
                          // setMode(0);
                          // setActiveTab(0);
                          searchParams.set("mode", "general");
                          searchParams.set("active", "tutorial");
                          setSearchParams(searchParams);
                        }}
                      >
                        Cancel
                      </SolButton>
                      <SolButton
                        onSol-click={() => {
                          // setMode(1);
                          // setActiveTab(1);
                          searchParams.set("mode", "gpt");
                          searchParams.set("active", "section");
                          setSearchParams(searchParams);
                        }}
                      >
                        Click to proceed <SolIcon icon="arrow_right_alt" />
                      </SolButton>
                    </div>
                  </>
                )}
                {activeTab !== "tutorial" && (
                  <>
                    <div className="flex flex-row items-center justify-between mb-12">
                      <div className="flex flex-row items-center space-x-10">
                        {STEPS?.map((step: any, idx: number) => (
                          <div
                            className={cn(
                              "flex flex-row items-center text-jll-icon-base-subdued",
                              {
                                "!text-jll-text-base-default":
                                  step?.value === activeTab,
                                "cursor-not-allowed !text-jll-icon-base-subdued":
                                  step?.value === "prompt" &&
                                  isDisabledGenerate,
                              }
                            )}
                            key={idx}
                          >
                            <div
                              className={cn(
                                "flex flex-row items-center space-x-2 py-4",
                                {
                                  "": step?.value === activeTab,
                                }
                              )}
                              onClick={() => {
                                if (
                                  step?.value === "prompt" &&
                                  isDisabledGenerate
                                )
                                  return;
                                searchParams.set("active", step?.value);
                                setSearchParams(searchParams);
                              }}
                            >
                              <div className="bg-jll-surface-base-secondary rounded-full w-7 h-7 flex justify-center items-center">
                                {idx + 1}
                              </div>
                              <span
                                className={cn("cursor-pointer", {
                                  "!cursor-not-allowed":
                                    step?.value === "prompt" &&
                                    isDisabledGenerate,
                                })}
                              >
                                {step?.label}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      {activeTab === "section" && (
                        <SolButton
                          variant="secondary"
                          onClick={() => {
                            // setActiveTab(2);
                            searchParams.set("active", "prompt");
                            setSearchParams(searchParams);
                          }}
                        >
                          Next
                        </SolButton>
                      )}
                      {activeTab === "prompt" && (
                        <div className="flex flex-row items-center space-x-4">
                          <SolButton
                            variant="tertiary"
                            onSol-click={() => {
                              // searchParams.set("mode", "general");
                              searchParams.set("active", "section");
                              setSearchParams(searchParams);
                            }}
                          >
                            Back
                          </SolButton>
                          <SolButton
                            variant="secondary"
                            onSol-click={() => {
                              handleGPT();
                            }}
                            disabled={isLoading}
                          >
                            Generate bio using JLL GPT
                          </SolButton>
                        </div>
                      )}
                      {activeTab === "main" && (
                        <div className="flex flex-row items-center space-x-4">
                          <SolButton
                            variant="tertiary"
                            onSol-click={() => {
                              searchParams.set("active", "prompt");
                              setSearchParams(searchParams);
                            }}
                          >
                            Back
                          </SolButton>
                          <SolButton
                            variant="secondary"
                            onSol-click={() => {
                              mainRef?.current?.saveBioData();
                            }}
                            disabled={isLoading}
                          >
                            Save
                          </SolButton>
                        </div>
                      )}
                    </div>

                    <div className="absolute left-0 right-0 bottom-0 top-[184px] w-full px-10 overflow-y-auto">
                      {activeTab === "main" && (
                        <MainBio
                          profile={profile}
                          setProfile={setProfile}
                          handleGPT={handleGPT}
                          ref={mainRef}
                          broker={broker}
                          reloadBroker={reloadBroker}
                        />
                      )}

                      {activeTab === "prompt" && <PromptBio ref={promptRef} />}

                      {activeTab === "section" && (
                        <SectionBio profile={profile} setProfile={setProfile} />
                      )}
                    </div>
                  </>
                )}
              </>
            )}

            {mode === "manual" && (
              <ManualBio
                profile={profile}
                setProfile={setProfile}
                broker={broker}
                reloadBroker={reloadBroker}
              />
            )}
          </div>
        )}
      </>
    );
  }
);

export default Bio;
