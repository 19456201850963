import { ArrowBackOutlined, Label } from "@material-ui/icons";
import { SolButton, SolIcon, SolTextField } from "@solstice/sol-react";
import { SET_LOADING_MODAL, STATUS_ACTIVE } from "constant";
import { GlobalContext } from "context";
import { useFormik } from "formik";
import { useApiCall } from "hooks";
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  deleteProfileAwardAPI,
  patchProfileAwardAPI,
  postProfileAwardAPI,
} from "services";
import { AwardProps, BrokerProps, OptionProps, ProfileProps } from "types";
import { SelectAutoComplete, Toast } from "ui-atoms";
import { LoadingPage } from "ui-molecules";
import { getApiPayloadFromForm } from "utils";
import * as Yup from "yup";

interface AwardsProps {
  profile: ProfileProps | null;
  setProfile?: any;
  isLoading?: boolean;
  broker?: BrokerProps | null;
  handlePublish?: any;
}

interface AwardItemProps {
  award: any;
  profile?: ProfileProps | null;
  setProfile?: any;
  setAwards?: any;
  index: number;
  awards?: any;
}

const INITIAL_VALUES = {
  award: "",
  organization: "",
  years: [],
  active_status: STATUS_ACTIVE,
};

const validationSchema = Yup.object().shape({
  award: Yup.string().required("This field is required"),
  organization: Yup.string().required("This field is required"),
  years: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
});

const startYear = 1990;
const currentYear = new Date().getFullYear();
const YEAR_OPTIONS = Array.from(
  { length: currentYear - startYear + 2 },
  (v, i) => currentYear + 1 - i
)?.map((value) => ({ label: value, value }));

const AwardItem: React.FC<AwardItemProps> = ({
  award,
  setProfile,
  profile,
  awards,
  setAwards,
  index,
}) => {
  const [searchParams] = useSearchParams();
  const [postProfileAward] = useApiCall(postProfileAwardAPI);
  const [patchProfileAward] = useApiCall(patchProfileAwardAPI);
  const [deleteProfileAward] = useApiCall(deleteProfileAwardAPI);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let formValues: any = {};
    Object.keys(INITIAL_VALUES)?.forEach((key: string) => {
      if (key === "years") {
        formValues = {
          ...formValues,
          [key]: (award as any)?.[key]?.map((year: string | number) => ({
            label: year,
            value: year,
          })),
        };
        return;
      }
      formValues = {
        ...formValues,
        [key]: (award as any)?.[key] ? (award as any)?.[key] : null,
      };
    });

    setValues({
      ...formValues,
    });
  }, [award]);

  const {
    setFieldValue,
    handleSubmit,
    values,
    setValues,
    touched,
    errors,
    handleBlur,
    isValid,
    dirty,
  } = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        if (award?.id) {
          let payload = getApiPayloadFromForm(profile?.awards?.[index], award);
          if (!payload || !Object.keys(payload)?.length) return;
          setIsLoading(true);
          patchProfileAward({
            ...payload,
            broker_profile: searchParams?.get("tab"),
            id: award?.id,
          })
            .then((res: any) => {
              if (!res) return;
              const idx = (profile?.awards || [])?.findIndex(
                (item) => item?.id?.toString() === award?.id?.toString()
              );
              if (idx > -1) {
                let updatedProfile = { ...profile };
                if (!!updatedProfile?.awards?.length) {
                  updatedProfile.awards[idx] = res;
                  setProfile(updatedProfile);
                }
              }
              let updatedAwards = [...awards];
              updatedAwards[index] = res;
              setAwards(updatedAwards);
            })
            .finally(() => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(true);
          postProfileAward({
            ...values,
            years: values.years.map((item: OptionProps) => item?.value),
            broker_profile: searchParams?.get("tab"),
          })
            .then((res: any) => {
              if (!res) return;
              let updatedProfile = { ...profile };
              let updatedAwards = [...awards];
              if (!updatedProfile?.awards?.length) updatedProfile.awards = [];
              updatedAwards[index] = res;
              updatedProfile.awards = updatedAwards?.filter((item) => item?.id);
              setProfile(updatedProfile);
              setAwards(updatedAwards);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } catch (err) {
        setIsLoading(false);
      }
    },
  });

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      if (award?.id) {
        await deleteProfileAward(award?.id);
        const idx = (profile?.awards || [])?.findIndex(
          (item) => item?.id?.toString() === award?.id?.toString()
        );
        if (idx > -1) {
          let updatedProfile = { ...profile };
          let updatedAwards = [...(updatedProfile?.awards || [])];
          updatedAwards?.splice(index, 1);
          updatedProfile.awards = updatedAwards;
          setProfile(updatedProfile);
        }
      }
      if (!!awards?.length) {
        let updatedAwards = [...awards];
        updatedAwards?.splice(index, 1);
        setAwards(updatedAwards);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-row items-center space-x-10">
      <form className="w-full grid grid-cols-3 gap-10">
        <SolTextField
          id="award"
          label="Name of the award"
          size="small"
          required
          className="col-span-1 w-full"
          value={values.award}
          onSol-input={(e: any) => {
            setFieldValue("award", e?.detail);
            const newAward = { ...award };
            newAward["award"] = e?.detail;
            setAwards((prev: any) => {
              const data = [...(prev || [])];
              data[index] = newAward;
              return data;
            });
          }}
          onBlur={handleBlur}
          disabled={isLoading}
          errorLabel={touched?.award ? errors?.award : ""}
        />

        <SolTextField
          id="organization"
          label="Name of the awarding organization"
          size="small"
          required
          className="col-span-1 w-full"
          value={values.organization}
          onSol-input={(e: any) => {
            setFieldValue("organization", e?.detail);
            const newAward = { ...award };
            newAward["organization"] = e?.detail;
            setAwards((prev: any) => {
              const data = [...(prev || [])];
              data[index] = newAward;
              return data;
            });
          }}
          onBlur={handleBlur}
          disabled={isLoading}
          errorLabel={touched?.organization ? errors?.organization : ""}
        />

        <SelectAutoComplete
          name={"years"}
          label="List the years when you have received this award"
          isMulti
          options={YEAR_OPTIONS}
          required
          size="sm"
          className="w-full"
          value={values.years}
          onChange={(e) => {
            setFieldValue("years", e);
            const newAward = { ...award };
            newAward["years"] = e?.map((item: OptionProps) => item?.value);
            setAwards((prev: any) => {
              const data = [...(prev || [])];
              data[index] = newAward;
              return data;
            });
          }}
          onBlur={handleBlur}
          error={touched?.years ? errors?.years : ""}
          isDisabled={isLoading}
        />
      </form>
      <div className="min-w-[70px] mt-1.5 flex flex-row space-x-4">
        <SolButton
          variant={!award?.id ? "primary" : "secondary"}
          className="sol-w-full"
          disabled={!isValid || !dirty || isLoading}
          onSol-click={() => handleSubmit()}
        >
          <span>{award?.id ? "Update" : "Save"}</span>
        </SolButton>
        <SolButton
          variant="secondary"
          className="sol-w-full"
          disabled={isLoading}
          itemType="button"
          onSol-click={() => handleDelete()}
        >
          Delete
        </SolButton>
      </div>
    </div>
  );
};

const Awards: React.FC<AwardsProps> = ({
  profile,
  setProfile,
  isLoading,
  broker,
  handlePublish,
}) => {
  const { dispatch } = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [awards, setAwards] = useState<AwardProps[]>([]);
  const [postProfileAward] = useApiCall(postProfileAwardAPI);
  const [patchProfileAward] = useApiCall(patchProfileAwardAPI);
  const isFirstRef = useRef(false);

  useEffect(() => {
    if (isFirstRef?.current) return;
    if (!profile || profile?.id?.toString() !== searchParams?.get("tab")) {
      setAwards([]);
      return;
    }
    setAwards(
      profile?.awards?.filter(
        (item) => item?.active_status === STATUS_ACTIVE
      ) || []
    );
    isFirstRef.current = true;
  }, [profile, isFirstRef.current]);

  const handleAction = async () => {
    try {
      if (awards?.length) {
        dispatch({
          type: SET_LOADING_MODAL,
          payload: {
            open: true,
            title: "Updating awards",
          },
        });
        // Save Awards
        let promises: any = [];
        let payload = getApiPayloadFromForm(profile, { awards }) || {};
        if (payload || Object.keys(payload)?.length) {
          awards?.forEach((award) => {
            if (
              !award?.award?.length ||
              !award?.organization?.length ||
              !award?.years?.length
            )
              return;
            promises.push(
              new Promise(async (resolve) => {
                if (award?.id) {
                  const res = await patchProfileAward({
                    ...award,
                    broker_profile: searchParams?.get("tab"),
                    id: award?.id,
                  });
                  if (!res) return;
                  const index = (profile?.awards || [])?.findIndex(
                    (item) => item?.id?.toString() === award?.id?.toString()
                  );
                  if (index > -1) {
                    let updatedProfile = { ...profile };
                    if (!!updatedProfile?.awards?.length) {
                      updatedProfile.awards[index] = res;
                      setProfile(updatedProfile);
                    }
                  }
                } else {
                  const res = await postProfileAward({
                    ...award,
                    broker_profile: searchParams?.get("tab"),
                  });
                  if (!res) return;
                  let updatedProfile = { ...profile };
                  if (!updatedProfile?.awards?.length)
                    updatedProfile.awards = [];
                  updatedProfile.awards.push(res);
                  setProfile(updatedProfile);
                }
                resolve("");
              })
            );
          });
        }

        if (!!promises?.length)
          await Promise.all(promises).finally(() => {
            isFirstRef.current = false;
          });
      }

      if (!profile?.licenses?.length) {
        searchParams.set(
          "sub",
          !profile?.licenses?.length ? "license" : "leader"
        );
        setSearchParams(searchParams);
      }
    } catch (err) {
    } finally {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: false,
          title: "",
        },
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingPage />
      ) : (
        <div className="w-full px-10 pt-8">
          <div className="mb-12">
            <h2 className="text-xl mb-1">Awards</h2>
            <p className="text-sm text-jll-text-base-subdued">
              Add any relevant awards and affiliations you hold. This
              information will display on the bottom of your public profile.
            </p>
          </div>
          <div className="flex flex-col space-y-2 mb-10">
            {awards?.map((award: any, idx: number) => (
              <AwardItem
                award={award}
                awards={awards}
                setAwards={setAwards}
                index={idx}
                key={idx}
                setProfile={setProfile}
                profile={profile}
              />
            ))}
            <SolButton
              variant="secondary"
              onSol-click={() => {
                if (awards?.length >= 6) {
                  Toast.warn(
                    "The Awards and Affiliations section should be limited to a maximum of 6 entries."
                  );
                  return;
                }
                setAwards((prev: AwardProps[]) => [
                  ...(prev || []),
                  {
                    award: "",
                    organization: "",
                    years: [],
                    active_status: STATUS_ACTIVE,
                  },
                ]);
              }}
            >
              Add another award <SolIcon icon="add" />
            </SolButton>
          </div>
          <div className="flex flex-row items-center space-x-10">
            <SolButton
              variant="outlined"
              onSol-click={() => {
                searchParams.set("sub", "markets");
                setSearchParams(searchParams);
              }}
            >
              <ArrowBackOutlined className="!w-5 !h-5" />
              Back
            </SolButton>
            {!profile?.licenses?.length && (
              <SolButton
                variant="outlined"
                onSol-click={() => {
                  searchParams.set(
                    "sub",
                    !profile?.licenses?.length ? "license" : "leader"
                  );
                  setSearchParams(searchParams);
                }}
              >
                Next
                <SolIcon icon="arrow_right_alt" />
              </SolButton>
            )}
            <SolButton onSol-click={handleAction}>Save</SolButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Awards;
