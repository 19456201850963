import { SolButton } from "@solstice/sol-react";
import { SET_LOADING_MODAL, STATUS_ACTIVE } from "constant";
import { GlobalContext, useNavigationHistory } from "context";
import { useApiCall } from "hooks";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { patchProfileAPI } from "services";
import { BrokerProps } from "types";
import { Table } from "ui-molecules";
import { getFormatedDate, getFormatedDateTime } from "utils";

interface InActiveProps {
  broker: BrokerProps | null;
  reloadBroker?: any;
}

const TABLE_COLUMNS = [
  {
    id: "country",
    label: "Country",
  },
  {
    id: "language",
    label: "Language",
  },
  {
    id: "updated_user",
    label: "Modified by",
  },
  {
    id: "update_timestamp",
    label: "Modified on",
  },
];

const InActive: React.FC<InActiveProps> = ({ broker, reloadBroker }) => {
  const { dispatch } = useContext(GlobalContext);
  const { navigate } = useNavigationHistory();
  const [patchProfile] = useApiCall(patchProfileAPI);

  useEffect(() => {
    if (!broker) return;
    const leng = (broker?.profiles || [])?.filter(
      (profile) => profile?.active_status !== STATUS_ACTIVE
    )?.length;

    if (!leng) navigate(-1);
  }, [broker]);

  const handleAction = (id: string | number) => {
    try {
      dispatch({
        type: SET_LOADING_MODAL,
        payload: {
          open: true,
          title: "Reactive Profile...",
        },
      });
      patchProfile({
        pk: id,
        active_status: STATUS_ACTIVE,
      })
        .then((res: any) => {
          if (!res) return;
          reloadBroker();
        })
        .finally(() => {
          dispatch({
            type: SET_LOADING_MODAL,
            payload: {
              open: false,
              title: "",
            },
          });
        });
    } catch (err) {}
  };

  return (
    <div className="flex flex-col w-full h-full relative overflow-hidden">
      <div className="flex flex-row items-center py-8 mx-10">
        <h4 className="text-xl">Inactive Bios</h4>
      </div>
      <div className="px-10">
        {
          <Table>
            <Table.Thead>
              <Table.Tr>
                {TABLE_COLUMNS?.map((column, idx) => (
                  <Table.Th key={idx}>{column?.label}</Table.Th>
                ))}
                <Table.Th>Action</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {broker?.profiles
                ?.filter((profile) => profile?.active_status !== STATUS_ACTIVE)
                ?.map((profile, idx) => (
                  <Table.Tr key={idx}>
                    {TABLE_COLUMNS?.map((column, idx1) => (
                      <Table.Td key={idx1}>
                        {(() => {
                          switch (column?.id) {
                            case "country":
                              return <>{profile?.country?.name || ""}</>;

                            case "language":
                              return <>{profile?.language_name || ""}</>;

                            case "updated_user":
                              return (
                                [
                                  profile?.updated_user_first_name,
                                  profile?.updated_user_last_name,
                                ].join(" ") || profile?.updated_user_email
                              );

                            case "update_timestamp":
                              return getFormatedDateTime(profile?.[column?.id]);

                            default:
                              return <></>;
                          }
                        })()}
                      </Table.Td>
                    ))}
                    <Table.Td>
                      <SolButton
                        variant="secondary"
                        onSol-click={() => handleAction(profile?.id)}
                      >
                        Re-active
                      </SolButton>
                    </Table.Td>
                  </Table.Tr>
                ))}
            </Table.Tbody>
          </Table>
        }
      </div>
    </div>
  );
};

export default InActive;
