import { forwardRef } from "react";
import cn from "classnames";

export interface TrProps {
  className?: string;
  children?: any;
  onClick?: (e?: any) => void;
  isEdit?: boolean;
  onAuxClick?: (e?: any) => void;
}

const Tr = forwardRef<any, TrProps>(
  ({ children, className, onClick, isEdit, onAuxClick, ...props }, ref) => {
    return (
      <tr
        className={cn(
          "border-b divide-jll-color-stroke-base-default relative",
          {
            "transition-colors cursor-pointer hover:bg-jll-surface-base-secondary-subdued":
              !!onClick,
          },
          className
        )}
        onClick={!!onClick ? onClick : undefined}
        ref={ref}
        onAuxClick={onAuxClick ? onAuxClick : undefined}
        {...props}
      >
        {children}
      </tr>
    );
  }
);

export default Tr;
